import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import loginServices from '../services/login_services'


export const loginStatus = {
    INTIAL: 'INTIAL',
}

export const loginSlice = createSlice(
    {
        name: 'login',
        initialState: {
            user: {
                email: null,
                password: null
            },
            isLoading: false,
            errorMessage: ""
        },
        reducers: {
            changeUserEmail: (state, email) => {
                state.user.email = email.payload
            },
            changeUserPassword: (state, password) => {
                state.user.password = password.payload
            },
            setFormErrors: (state, message) => {

                state.formErrorMessage = message.payload
                state.isLoading = false
            },
            setAddFormErrors: (state, message) => {

                state.errorMessage = message.payload ?? 'Username and Password do not match'

                state.isLoading = false
            },
            setUserData: (state, user) => {
                localStorage.setItem("token", user.payload.data.accessToken)
                localStorage.setItem("user", JSON.stringify(user.payload.data.user))
            },
            setEmployeeList: (state, employee) => {
                state.isLoading = false
                state.employeeList = employee.payload.data
            },
            setLoadingHidden: (state) => {
                state.isLoading = false
            },
            setLoadingScreen: (state) => {
                state.isLoading = true
            },
        },
    })


export const loginUser = createAsyncThunk(
    "auth/login",
    async ({ email, password }, thunkAPI) => {

        try {
            if (loginServices.validate_login(email, password)) {
                var response = await loginServices.login(email, password)
                thunkAPI.dispatch(setUserData(response))
                thunkAPI.fulfillWithValue()
            } else {
                throw ({

                    response: {
                        data: {
                            message: "확인 오류"
                        }
                    }
                })
            }

        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))
            return thunkAPI.rejectWithValue()
        }
    }
)

// Action creators are generated for each case reducer function
export const { changeUserEmail, setUserData, changeUserPassword, setAddFormErrors, setFormErrors, setLoadingHidden, setLoadingScreen } = loginSlice.actions

var loginReducer = loginSlice.reducer

export default loginReducer