import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import adminloginServices from '../services/admin_login_services'


export const adminloginStatus = {
    INTIAL: 'INTIAL',
}

export const adminloginSlice = createSlice(
    {
        name: 'adminlogin',
        initialState: {
            user: {
                email: null,
                password: null
            },
            isLoading: false,
            errorMessage: ""
        },
        reducers: {
            changeUserEmail: (state, email) => {
                state.user.email = email.payload
            },
            changeUserPassword: (state, password) => {
                state.user.password = password.payload
            },
            setFormErrors: (state, message) => {

                state.formErrorMessage = message.payload
                state.isLoading = false
            },
            setUserData: (state, user) => {
                localStorage.setItem("admin_token", user.payload.data.accessToken)
                localStorage.setItem("admin_user", JSON.stringify(user.payload.data.user))
            },
            setAddFormErrors: (state, message) => {

                state.errorMessage = message??'Username and Password do not match'

                state.isLoading = false
            },
            setEmployeeList: (state, employee) => {
                state.isLoading = false
                state.employeeList = employee.payload.data
            },
            setLoadingHidden: (state) => {
                state.isLoading = false
            },
            setLoadingScreen: (state) => {
                state.isLoading = true
            },
        },
    })


export const adminloginUser = createAsyncThunk(
    "auth/adminlogin",
    async ({ email, password }, thunkAPI) => {

        try {
            if (adminloginServices.validate_adminlogin(email, password)) {
                var response = await adminloginServices.adminlogin(email, password)
                thunkAPI.dispatch(setUserData(response))
            }
            thunkAPI.fulfillWithValue()
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))
            return thunkAPI.rejectWithValue()
        }
    }
)

// Action creators are generated for each case reducer function
export const { changeUserEmail, changeUserPassword, setUserData, setAddFormErrors, setFormErrors, setLoadingHidden, setLoadingScreen } = adminloginSlice.actions

var adminloginReducer = adminloginSlice.reducer

export default adminloginReducer