import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import adminhomeServices from '../services/admin_home_services'
import { goToLogin } from '../../common/repository/api_routes'


export const adminhomeStatus = {
    INTIAL: 'INTIAL',
}

export const adminhomeSlice = createSlice(
    {
        name: 'adminhome',
        initialState: {
            user: {
                email: null,
                password: null
            },
            faq: {
                id: null,
                title: null,
                content: null
            },
            pointnew: {
                amount: 0,
                reason: '',
                type: 'credit'
            },
            request: {
                id: null,
                name: null,
                main_keyword: null,
                sub_keywords: null,
                place_url: null,
                hashtags: null,
                main_image: null,
                blogger_limit: null,
                due_date: null,
                searched_url: null,
                restricted_users:[],
                order: 10,
                images: [

                ]
            },
            selectedUser: {
                bank_detail: {

                }
            },
            userBlogs: null,
            bankApprove: null,
            isLoading: false,
            userList: null,
            addUserModal: false,
            requestList: null,
            faqList: null,
            faqModalOpen: false,
            isClone: false,
            errorMessage: "",
            formErrors: ""
        },
        reducers: {
            changeUserEmail: (state, email) => {
                state.user.email = email.payload
            },
            changeRequestUserList: (state, users) => {
                state.request.restricted_users = users.payload
            },
            changePointAmount: (state, amount) => {
                state.pointnew.amount = amount.payload
            },
            changePointType: (state, type) => {
                state.pointnew.type = type.payload
            },
            changePointReason: (state, reason) => {
                state.pointnew.reason = reason.payload
            },
            changeOrder: (state, order) => {
                state.request.order = order.payload
            },
            changeFaqTitle: (state, title) => {
                state.faq.title = title.payload
            },
            changeUserPhone: (state, data) => {
                state.selectedUser.phone = data.payload
            },
            changeSearchUrl: (state, searched_url) => {
                state.request.searched_url = searched_url.payload
            },
            changeUserPasswordNew: (state, data) => {
                state.selectedUser.password = data.payload
            },
            changeUserEmailAddress: (state, data) => {
                state.selectedUser.email = data.payload
            },
            changeUserBlogId: (state, data) => {
                state.selectedUser.blog_id = data.payload
            },
            changeUserBankName: (state, data) => {
                if (state.selectedUser.bank_detail == null) {
                    state.selectedUser.bank_detail = {}
                }
                state.selectedUser.bank_detail.bank_name = data.payload
            },
            changeUserBankNo: (state, data) => {
                if (state.selectedUser.bank_detail == null) {
                    state.selectedUser.bank_detail = {}
                }
                state.selectedUser.bank_detail.account_number = data.payload
            },
            changeUserBankAccName: (state, data) => {
                if (state.selectedUser.bank_detail == null) {
                    state.selectedUser.bank_detail = {}
                }
                state.selectedUser.bank_detail.account_name = data.payload
            },
            changeUserBankIdNo: (state, data) => {
                if (state.selectedUser.bank_detail == null) {
                    state.selectedUser.bank_detail = {}
                }
                state.selectedUser.bank_detail.identification_number = data.payload
            },
            changeaddUserModal: (state, user) => {
                var usernew = user.payload;
                if (usernew.bank_detail == null) {
                    usernew = { ...usernew, bank_detail: {} }
                }
                state.selectedUser = usernew
                state.addUserModal = !state.addUserModal
            },
            setUpdateRequest: (state, request) => {

                var zz = { ...request.payload };
                zz.images = {

                }
                zz.images = request.payload.images.map((e) => {
                    return e.image
                })

                state.request = zz
                state.isClone = false;
                state.faqModalOpen = !state.faqModalOpen

            },
            setCloneRequest: (state, request) => {

                var zz = { ...request.payload };
                zz.images = {

                }
                zz.images = request.payload.images.map((e) => {
                    return e.image
                })

                state.request = zz
                state.isClone = true;
                state.faqModalOpen = !state.faqModalOpen

            },
            changeFaqContent: (state, content) => {
                state.faq.content = content.payload
            },
            changeFaqModalOpen: (state) => {
                state.faq.id = null
                state.faq.title = null
                state.faq.content = null

                state.request = {
                    id: null,
                    name: null,
                    main_keyword: null,
                    sub_keywords: null,
                    place_url: null,
                    hashtags: null,
                    main_image: null,
                    blogger_limit: null,
                    order: 10,
                    due_date: '2100-01-01',
                    images: [

                    ]
                }

                state.faqModalOpen = !state.faqModalOpen
            },
            changeUserPassword: (state, password) => {
                state.user.password = password.payload
            },
            changeRequestName: (state, name) => {
                state.request.name = name.payload
            },
            changeRequestMainKeyword: (state, main_keyword) => {
                state.request.main_keyword = main_keyword.payload
            },
            changeRequestSubKeyword: (state, sub_keywords) => {
                state.request.sub_keywords = sub_keywords.payload
            },
            changeRequestPlaceURL: (state, place_url) => {
                state.request.place_url = place_url.payload
            },
            changeRequestHashTag: (state, hashtags) => {
                state.request.hashtags = hashtags.payload
            },
            changeRequestMainImage: (state, main_image) => {
                state.request.main_image = main_image.payload
            },
            changeRequestBlogLimit: (state, blogger_limit) => {
                state.request.blogger_limit = blogger_limit.payload
            },
            changeRequestDueDate: (state, due_date) => {
                state.request.due_date = due_date.payload
            },
            changeUserPassword: (state, password) => {
                state.user.password = password.payload
            },
            setFormErrors: (state, message) => {

                state.formErrors = message.payload
                state.isLoading = false
            },
            setAddFormErrors: (state, message) => {

                state.formErrors = message.payload
                state.isLoading = false
            },
            setEmployeeList: (state, employee) => {
                state.isLoading = false
                state.employeeList = employee.payload.data
            },
            setNewPlaceMainFile: (state, file) => {
                state.request.main_image = file.payload;
            },
            setMainFileData: (state, message) => {
                state.request.main_image = message.payload.data.filename;
            },
            addSubFileData: (state, message) => {
                state.request.images = [...state.request.images, message.payload.data.filename];
            },

            removeSubFileData: (state, message) => {
                state.request.images = state.request.images.filter((item) => item != message.payload);

            },
            removeMainFileData: (state) => {
                state.request.main_image = '';

            },
            setLoadingHidden: (state) => {
                state.isLoading = false
            },
            setUpdateFaq: (state, faq) => {

                state.faq.id = faq.payload.id
                state.faq.title = faq.payload.title
                state.faq.content = faq.payload.content
                state.faqModalOpen = !state.faqModalOpen
            },
            setuserList: (state, userList) => {
                state.userList = userList.payload
            },
            setUserBlogs: (state, userBlogs) => {
                state.userBlogs = userBlogs.payload
            },
            setBankApprove: (state, bankApprove) => {
                state.bankApprove = bankApprove.payload
            },
            setRequestList: (state, requestList) => {
                state.requestList = requestList.payload
                state.isLoading = false
            },
            setFaqList: (state, faqList) => {
                state.faqList = faqList.payload
            },
            setLoadingScreen: (state) => {
                state.isLoading = true
            },
        },
    })


export const getusers = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await adminhomeServices.getusers()

            thunkAPI.dispatch(setuserList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)



export const getbankapprove = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await adminhomeServices.getBankApprovals()

            thunkAPI.dispatch(setBankApprove(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const bankApprove = createAsyncThunk(
    "auth/create_employee",
    async ({ id }, thunkAPI) => {

        try {

            var data = await adminhomeServices.bankApprove(id)

            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const getblogs = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await adminhomeServices.getBlogs()

            thunkAPI.dispatch(setUserBlogs(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const getfaq = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await adminhomeServices.getFaq()

            thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const getrequests = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await adminhomeServices.getRequests()

            thunkAPI.dispatch(setRequestList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const uploadPhotoMain = createAsyncThunk(
    "auth/uploadPhoto",
    async (file, thunkAPI) => {

        try {
            var data = await adminhomeServices.uploadPhoto(file);

            thunkAPI.dispatch(setMainFileData(data));

            return thunkAPI.fulfillWithValue();

        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();


            thunkAPI.dispatch(setFormErrors(message));

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue();
        }
    }
);

export const uploadPhotoSub = createAsyncThunk(
    "auth/uploadPhoto",
    async (file, thunkAPI) => {

        try {
            for (var i = 0; i < file.length; i++) {
                var data = await adminhomeServices.uploadPhoto(file[i]);

                thunkAPI.dispatch(addSubFileData(data));
            }


            return thunkAPI.fulfillWithValue();

            // return { user: data };
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();


            thunkAPI.dispatch(setFormErrors(message));

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue();
        }
    }
);


export const blockUser = createAsyncThunk(
    "auth/create_employee",
    async (uid, thunkAPI) => {

        try {

            var data = await adminhomeServices.blockUser(uid)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const unBlockUser = createAsyncThunk(
    "auth/create_employee",
    async (uid, thunkAPI) => {

        try {

            var data = await adminhomeServices.unBlockUser(uid)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)
export const saveRequests = createAsyncThunk(
    "auth/create_employee",
    async (formData, thunkAPI) => {

        try {

            var data = await adminhomeServices.saveRequests(formData)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const updateRequests = createAsyncThunk(
    "auth/create_employee",
    async ({ id, formData }, thunkAPI) => {

        try {

            var data = await adminhomeServices.updateRequests(id, formData)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)




export const activeRequests = createAsyncThunk(
    "auth/create_employee",
    async ({ id }, thunkAPI) => {

        try {

            await adminhomeServices.activeRequest(id)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const inactiveRequests = createAsyncThunk(
    "auth/create_employee",
    async ({ id }, thunkAPI) => {

        try {
            
            var data = await adminhomeServices.inactiveRequest(id)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const deleteRequests = createAsyncThunk(
    "auth/create_employee",
    async (id, thunkAPI) => {

        try {

            var data = await adminhomeServices.deleteRequests(id)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const savefaq = createAsyncThunk(
    "auth/create_employee",
    async ({ title, content }, thunkAPI) => {

        try {

            var data = await adminhomeServices.saveFaq(title, content)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const updatefaq = createAsyncThunk(
    "auth/create_employee",
    async ({ id, title, content }, thunkAPI) => {

        try {

            var data = await adminhomeServices.updateFaq(id, title, content)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const addPoints = createAsyncThunk(
    "auth/create_employee",
    async ({ id, amount, reason, type }, thunkAPI) => {

        try {

            await adminhomeServices.addPoints(id, amount, reason, type)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)



export const updateUserNew = createAsyncThunk(
    "auth/create_employee",
    async ({ id, formData }, thunkAPI) => {

        try {

            var data = await adminhomeServices.updateUser(id, formData)
            if (data.error != null && data.error) {

                var message = data.message


                Object.entries(data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })

                thunkAPI.dispatch(setAddFormErrors(message))
                thunkAPI.abort()

            } else {
                // thunkAPI.dispatch(setFaqList(data.data.data))
                thunkAPI.fulfillWithValue()
            }
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const deleteUser = createAsyncThunk(
    "auth/create_employee",
    async ({ id }, thunkAPI) => {

        try {

            await adminhomeServices.deleteUser(id)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const deleteFaq = createAsyncThunk(
    "auth/create_employee",
    async ({ id }, thunkAPI) => {

        try {

            var data = await adminhomeServices.deleteFaq(id)

            // thunkAPI.dispatch(setFaqList(data.data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }
            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

// Action creators are generated for each case reducer function
export const { changeUserEmail,changeRequestUserList, changeOrder, changePointAmount, changePointReason, changePointType, changeUserEmailAddress, changeUserPasswordNew, changeSearchUrl, setBankApprove, setUserBlogs, changeUserBankAccName, changeUserBankIdNo, changeUserBankName, changeUserBankNo, changeUserBlogId, changeUserPhone, changeaddUserModal, setUpdateRequest, setRequestList, removeMainFileData, removeSubFileData, addSubFileData, setMainFileData, setNewPlaceMainFile, changeRequestBlogLimit, changeRequestDueDate, changeRequestHashTag, changeRequestMainImage, changeRequestMainKeyword, changeRequestName, changeRequestPlaceURL, changeRequestSubKeyword, changeFaqModalOpen, setCloneRequest, setUpdateFaq, changeFaqContent, changeFaqTitle, changeUserPassword, setFaqList, setuserList, setAddFormErrors, setFormErrors, setLoadingHidden, setLoadingScreen } = adminhomeSlice.actions

var adminhomeReducer = adminhomeSlice.reducer

export default adminhomeReducer