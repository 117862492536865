import { toast } from "react-toastify";
import registerRepo from "../repository/register_repo";

async function register(email, password, phone, blog_id) {

    var response = await registerRepo.register(email, password, phone, blog_id)

    return response;
}


async function bankAccountSave(bank_name, account_number, account_name, identification1, identification2) {

    var response = await registerRepo.bankAccountSave(bank_name, account_number, account_name, identification1, identification2)

    return response;
}

function validate_register(email, password, confirm_password, phone, blog_id) {

    if (email == null || email.trim() == "") {
        toast.error("Email을 입력해주세요.")
        return false
    }
    if (!email.trim().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        toast.error("유효하지 않은 이메일 주소 형식입니다.")
        return false
    }

    if (password == null || password.trim() == "") {
        toast.error("비밀번호를 입력해주세요")
        return false
    }

    if (password.trim().length < 5) {
        toast.error("비밀번호는 5자 이상이어야 합니다.")
        return false
    }

    if (confirm_password != password) {
        toast.error("2개의 비밀번호가 일치하지 않습니다. 다시 입력해주세요")
        return false
    }

    if (blog_id == null || blog_id.trim().replaceAll("https://blog.naver.com/","") == "") {
        toast.error("Blog ID를 입력해주세요.")
        return false
    }

    if (phone == null || !phone.replaceAll("-", "").match(/^[010]{3}[0-9]{8}$/)) {
        toast.error("전화번호가 올바르지 않습니다. 예: 01012345678")
        return false
    }
    return true
}



function validate_bank(bank_name, account_number, account_name, identification1, identification2) {

    if (bank_name == null || bank_name.trim() == "") {
        toast.error("은행 이름이 비어 있습니다.")
        return false
    }
    if (bank_name != null && !bank_name.match(/[\u3131-\uD79D]/ugi)) {
        toast.error("은행 이름은 숫자를 포함할 수 없습니다.")
        return false
    }

    if (account_number == null || account_number.trim() == "") {
        toast.error("은행 계좌 번호가 비어 있습니다.")
        return false
    }

    if (account_number != null && !account_number.match(/^[0-9]+$/)) {
        toast.error("은행 이름은 숫자만 포함할 수 있습니다.")
        return false
    }

    if (account_name == null || account_name.trim() == "") {
        toast.error("은행 계좌 이름이 비어 있습니다.")
        return false
    }

    if (account_name != null && !account_name.match(/[\u3131-\uD79D]/ugi)) {
        toast.error("은행 계좌 이름이 잘못되었습니다.")
        return false
    }

    if (identification1 != null && !identification1.match(/^[0-9]+$/)) {
        toast.error("식별 번호가 유효하지 않습니다")
        return false
    }

    if (identification2 != null && !identification2.match(/^[0-9]+$/)) {
        toast.error("식별 번호가 유효하지 않습니다")
        return false
    }

    if (identification1 == null || identification1.trim().length < 6) {
        toast.error("ID는 5자 이상이어야 합니다.")
        return false
    }

    if (identification2 == null || identification2.trim().length < 1) {
        toast.error("ID 2는 1자 이상이어야 합니다.")
        return false
    }

    return true
}

const registerServices = {
    register,
    validate_register,
    validate_bank,
    bankAccountSave
}

export default registerServices