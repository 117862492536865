import { useSelector } from "react-redux";
import RegisterViewStep2 from "./RegisterViewStep2";

export default function BankAccountCreate() {

    const formNo = useSelector((state) => state.register.formNo)

    return (
        <div className="App">
            <RegisterViewStep2 />
        </div>
    )
}