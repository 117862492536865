import { toast } from "react-toastify";
import adminloginRepo from "../repository/admin_login_repo";

async function adminlogin(email, password) {

    var response = await adminloginRepo.adminlogin(email, password)

    return response;
}

function validate_adminlogin(email, password) {

    if (email == null || email.trim() == "") {
        toast.error("Email is empty")
        return false
    }
    if (!email.trim().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        toast.error("Email is not valid, please enter valid email")
        return false
    }

    if (password == null || password.trim() == "") {
        toast.error("Password is empty")
        return false
    }

    if (password.trim().length < 5) {
        toast.error("Password need to contain atleast 5 characters")
        return false
    }

    return true
}

const adminloginServices = {
    adminlogin,
    validate_adminlogin
}

export default adminloginServices

