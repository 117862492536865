import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import faqServices from '../services/faq_services'


export const faqStatus = {
    INTIAL: 'INTIAL',
}

export const faqSlice = createSlice(
    {
        name: 'faq',
        initialState: {
            user: {
                email: null,
                password: null
            },
            faqList: null,
            isLoading: false,
            errorMessage: ""
        },
        reducers: {
            changeUserEmail: (state, email) => {
                state.user.email = email.payload
            },
            setFaqList: (state, faqList) => {
                state.faqList = faqList.payload.data.data
            },
            changeUserPassword: (state, password) => {
                state.user.password = password.payload
            },
            setFormErrors: (state, message) => {
                state.formErrorMessage = message.payload
                state.formProcessing = false
            },
            setAddFormErrors: (state, message) => {
                state.addEmployeeErrors = message.payload
                state.formProcessing = false
            },
            setEmployeeList: (state, employee) => {
                state.formProcessing = false
                state.employeeList = employee.payload.data
            },
            setLoadingHidden: (state) => {
                state.formProcessing = false
            },
            setLoadingScreen: (state) => {
                state.formProcessing = true
            },
        },
    })


export const faqListGet = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await faqServices.faq()

            thunkAPI.dispatch(setFaqList(data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))
            return thunkAPI.rejectWithValue()
        }
    }
)

// Action creators are generated for each case reducer function
export const { changeUserEmail, changeUserPassword, setFaqList, setAddFormErrors, setFormErrors, setLoadingHidden, setLoadingScreen } = faqSlice.actions

var faqReducer = faqSlice.reducer

export default faqReducer