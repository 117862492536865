import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import store from './store';
import Register from './modules/register/views/Register';
import Faq from './modules/faq/views/Faq';
import HomeView from './modules/home/views/HomeView';
import Applied from './modules/home/views/Applied';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import AdminLoginView from './modules/adminlogin/views/AdminLoginView';
import AdminHome from './modules/adminuser/views/Home';
import FAQHome from './modules/adminuser/views/Faq';
import RequestHome from './modules/adminuser/views/Request';
import Completed from './modules/home/views/Compled';
import PlaceDetailView from './modules/home/views/PlaceDetails';
import MyInfo from './modules/home/views/MyInfo';
import BankAccountCreate from './modules/register/views/BankAccountCreate';
import MyPoints from './modules/home/views/MyPoints';
import SubmissionHome from './modules/adminuser/views/Submissions';
import BankRequestsHome from './modules/adminuser/views/BankRequests';
import MyPointsAdmin from './modules/adminuser/views/MyPointsAdmin';
import PlaceDetailAdminView from './modules/adminuser/views/PlaceDetailsAdmin';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  <Provider store={store}>
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<App />} />
          <Route path="login" element={<App />} />
          <Route path="register" element={<Register />} />
          <Route path="faq" element={<Faq />} />
          <Route path="home" element={<HomeView />} />
          <Route path="applied" element={<Applied />} />
          <Route path="details" element={<PlaceDetailView />} />
          <Route path="completed" element={<Completed />} />
          <Route path="admin_login" element={<AdminLoginView />} />
          <Route path="admin_home" element={<AdminHome />} />
          <Route path="admin_faq" element={<FAQHome />} />
          <Route path="admin_requests" element={<RequestHome />} />
          <Route path="my_info" element={<MyInfo />} />
          <Route path="bank_details" element={<BankAccountCreate />} />
          <Route path="my_points" element={<MyPoints />} />
          <Route path="verified_blogs" element={<SubmissionHome />} />
          <Route path="bank_approvals" element={<BankRequestsHome />} />
          <Route path="points_admin" element={<MyPointsAdmin />} />
          <Route path="place_detail" element={<PlaceDetailAdminView />} />
        </Route>
      </Routes>
    </BrowserRouter>

  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
