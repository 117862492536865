import faqRepo from "../repository/faq_repo";

async function faq() {
    var token = localStorage.getItem("admin_token")
    var response = await faqRepo.getFaq(token)

    return response;
}

const faqServices = {
    faq
}

export default faqServices