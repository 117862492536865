

class ApiRoute {
    constructor(route, method) {
        this.route = route;
        this.method = method;
    }
}


const LOGIN_API_ROUTE = new ApiRoute("/auth/login", "POST")
const ADMIN_LOGIN_API_ROUTE = new ApiRoute("/admin/auth/login", "POST")
const REGISTER_API_ROUTE = new ApiRoute("/auth/register", "POST")
const GET_USERS_ADMIN = new ApiRoute("/admin/user", "GET")
const GET_FAQ_ADMIN = new ApiRoute("/admin/faq", "GET")
const GET_FAQ_USER = new ApiRoute("/faq", "GET")
const IMAGE_UPLOAD = new ApiRoute("/admin/request/upload", "POST")
const ADD_REQUEST = new ApiRoute("/admin/request", "POST")
const ALL_PLACES = new ApiRoute("/place", "GET")

const baseUrl = "https://api2.reviewnara.store/api/v1"
const downloadUrl = "https://api2.reviewnara.store"
const IMG_URL = "https://api2.reviewnara.store/storage/places"

// const baseUrl = "http://localhost:8000/api/v1"
// const downloadUrl = "http://localhost:8000"
// const IMG_URL = "http://localhost:8000/storage/places"

function goToLogin() {
    localStorage.clear()
    window.top.location.href = '/'
}

export { LOGIN_API_ROUTE, ADMIN_LOGIN_API_ROUTE, goToLogin, downloadUrl, ALL_PLACES, REGISTER_API_ROUTE, baseUrl, GET_USERS_ADMIN, IMG_URL, GET_FAQ_ADMIN, GET_FAQ_USER, IMAGE_UPLOAD, ADD_REQUEST }