import MyInfoView from "./MyInfoView";

function MyInfo() {

    return (
        <div className="App">
            <MyInfoView />
        </div>
    );
}

export default MyInfo;