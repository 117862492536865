import adminhomeRepo from "../repository/admin_home_repo";

async function getusers() {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.getUsers(token)

    return response;
}

async function getBankApprovals() {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.getBankApprovals(token)

    return response;
}

async function bankApprove(id) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.bankApprove(id, token)

    return response;
}

async function getBlogs() {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.getSubmissions(token)

    return response;
}


async function blockUser(uid) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.blockUser(uid, token)

    return response;
}

async function unBlockUser(uid) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.unBlockUser(uid, token)

    return response;
}

async function getRequests() {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.getRequests(token)

    return response;
}

async function uploadPhoto(file) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.uploadImage(file, token)

    return response;
}

async function getFaq() {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.getFaq(token)

    return response;
}

async function saveFaq(title, content) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.savetFaq(title, content, token)

    return response;
}

async function saveRequests(formData) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.saveRequests(formData, token)

    return response;
}


async function updateRequests(id, formData) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.updateRequests(id, formData, token)

    return response;
}

async function activeRequest(id) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.activeRequest(id, token)

    return response;
}

async function inactiveRequest(id) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.inactiveRequest(id, token)

    return response;
}


async function deleteRequests(id) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.deleteRequests(id, token)

    return response;
}

async function addPoints(id, amount, reason, type) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.addPoints(id, amount, reason, type, token)

    return response;
}

async function updateFaq(id, title, content) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.updateFaq(id, title, content, token)

    return response;
}

async function updateUser(id, formData) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.updateUser(id, formData, token)

    return response;
}

async function deleteUser(id) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.deleteUser(id, token)

    return response;
}

async function deleteFaq(id) {
    var token = localStorage.getItem("admin_token")
    var response = await adminhomeRepo.deleteFaq(id, token)

    return response;
}




const adminhomeServices = {
    getusers,
    getFaq,
    saveFaq,
    updateFaq,
    deleteFaq,

    getRequests,
    uploadPhoto,
    saveRequests,
    updateRequests,
    deleteRequests,
    blockUser,
    unBlockUser,
    updateUser,
    deleteUser,
    getBlogs,
    getBankApprovals,
    bankApprove,
    addPoints,

    activeRequest,
    inactiveRequest

}

export default adminhomeServices