import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadpoints, setLoadingScreen } from "../state/home_state";
import { Typography, Box, Toolbar, } from "@mui/material"
import { DataGrid, koKR } from "@mui/x-data-grid";
import { APP_NAME } from "../../common/constants/ConfigApp";

import AppBarComponent from "../../common/views/components/AppBarComponent";
import Sidebar from "../../common/views/components/SideBar";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import CssBaseline from '@mui/material/CssBaseline';

function MyPointsView() {

    const points = useSelector((state) => state.home.points)
    const isLoading = useSelector((state) => state.home.isLoading)

    const [mobileOpen, setMobileOpen] = useState(false);

    const dispatch = useDispatch()

    function initState() {
        dispatch(setLoadingScreen({}))
        dispatch(loadpoints({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {
            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerWidth = 300;

    const columns = [
        { field: 'status', headerName: '상태', flex: 1.5 },
        { field: 'date_time', headerName: '일시', flex: 1.5 },
        { field: 'point', headerName: '포인트', flex: 1 },
        { field: 'total', headerName: '총 포인트', flex: 1.5 },
    ];

    var rows = [];
    var totalPoints = 0;

    if (points != null) {
        rows = (points).map((point, index) => {
            totalPoints += point.amount * (point.type == "debit" ? -1 : 1);
            return {
                id: index + 1,
                status: point.reason,
                date_time: getFormatedDate(point.created_at),
                point: point.amount * (point.type == "debit" ? -1 : 1),
                total: parseInt(point.total_points)
            }
        })
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBarComponent
                title={APP_NAME}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Sidebar
                drawerOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
            />
            
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                
                <LoadingIndicator
                    show={isLoading}
                />

                <Box sx={{ padding: 0, }}>
                    <div style={{ height: '70vh', width: '100%' }}>
                        <Typography variant="h5">
                            내 포인트 {totalPoints}
                        </Typography>
                        <br />

                        <DataGrid
                            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            rowSelection={false}
                        />
                    </div>
                </Box>
            </Box>
        </Box>
    );
}

function getFormatedDate(dateNow) {
    const date = new Date(dateNow);

    // Extract the individual components of the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the components into the desired format
    // const formattedDate = `${year}.${month}.${day} ${hours}.${minutes}.${seconds}`;
    const formattedDate = `${year}.${month}.${day}`;

    return formattedDate;
}

export default MyPointsView;