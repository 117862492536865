import { Button, Card, Container } from "@mui/material";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import SidebarAdmin from "../../common/views/components/SideBarAdmin";
import { useDispatch, useSelector } from "react-redux";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridValueGetterParams, koKR } from '@mui/x-data-grid';
import { blockUser, changeaddUserModal, deleteUser, getusers, setLoadingHidden, setLoadingScreen, unBlockUser } from "../state/admin_home_state";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { EditUserModal } from "./EditUserModal";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";


function HomeViewAdmin() {

    const users = useSelector((state) => state.adminhome.userList)
    var dispatch = useDispatch()
    const errorMessage = useSelector((state) => state.adminhome.errorMessage)
    const isLoading = useSelector((state) => state.adminhome.isLoading)
    const changeaddUserModal2 = useSelector((state) => state.adminhome.addUserModal)

    var navigator = useNavigate();


    function initState() {

        dispatch(getusers({}))
    }
    function handleBlockUser(uid) {
        dispatch(setLoadingScreen({}))
        dispatch(blockUser(uid)).unwrap().then(() => {
            dispatch(setLoadingHidden())
            toast.success("Blocked Successfully")
            dispatch(getusers({}))
        })
    }
    function handleDeleteUser(uid) {
        dispatch(setLoadingScreen({}))
        dispatch(deleteUser({ id: uid })).unwrap().then(() => {
            dispatch(setLoadingHidden())
            toast.success("Deleted Successfully")
            dispatch(getusers({}))
        })
    }

    function handleUnBlockUser(uid) {
        dispatch(setLoadingScreen({}))
        dispatch(unBlockUser(uid)).unwrap().then(() => {
            dispatch(setLoadingHidden())
            toast.success("Un-Blocked Successfully")
            dispatch(getusers({}))
        })
    }
    useEffect(() => {
        if (document.readyState === 'complete') {

            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);


    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'email', headerName: 'Email', width: 180 },
        { field: 'phone', headerName: 'Phone', width: 150 },
        { field: 'blog_id', headerName: 'Blog ID', width: 230 },
        { field: 'bank_name', headerName: 'Bank Name', width: 130 },
        { field: 'account_number', headerName: 'Acc #', width: 130 },
        { field: 'bank_user_name', headerName: 'Owner Name', width: 130 },
        { field: 'id_no', headerName: 'Identification #', width: 130 },
        {
            field: 'points',
            headerName: 'Points',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                if (params.row.user != null) {
                    return <a style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => {
                        navigator("/points_admin", {
                            state: {
                                user: params.row.user,
                            }
                        })
                    }}>
                        {params.row.user.points}
                    </a>
                }
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 260,
            renderCell: (params) => {
                if (params.row.user != null) {
                    if (params.row.user.active == 1) {
                        return <div>
                            <Button onClick={() => {
                                dispatch(changeaddUserModal(params.row.user))
                            }} variant="contained" color="warning">Edit</Button>&nbsp;
                            <Button onClick={() => {
                                handleBlockUser(params.row.user.id)
                            }} variant="contained">Block</Button>&nbsp;
                            <Button onClick={() => {
                                handleDeleteUser(params.row.user.id)
                            }} variant="contained" color="error">Delete</Button>
                        </div>

                    } else {
                        return <div>
                            <Button onClick={() => {
                                dispatch(changeaddUserModal(params.row.user))
                            }} variant="contained" color="warning">Edit</Button>&nbsp;
                            <Button onClick={() => {
                                handleUnBlockUser(params.row.user.id)
                            }} variant="contained">UnBlock</Button> & nbsp;
                            <Button onClick={() => {
                                handleBlockUser(params.row.user.id)
                            }} variant="contained">Block</Button> & nbsp;
                            <Button onClick={() => {
                                handleDeleteUser(params.row.user.id)
                            }} variant="contained" color="error">Delete</Button>
                        </div>

                    }
                }
            }
        },
    ];

    var rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    ];

    if (users != null) {
        rows = (users).filter((el) => el.email != "admin@gmail.com").map((user, index) => {
            if (user.bank_detail != null) {
                return { id: index + 1, email: user.email, points: user.points, blog_id: user.blog_id, phone: user.phone, bank_name: user.bank_detail.bank_name, account_number: user.bank_detail.account_number, bank_user_name: user.bank_detail.account_name, id_no: user.bank_detail.identification_number, user: user }
            } else {
                return { id: index + 1, email: user.email, points: user.points, blog_id: user.blog_id, phone: user.phone, user: user }
            }
        })
    }

    return (

        <div>
            <AppBarComponent
                title={APP_NAME}
                isAdmin={true}
            />
            <LoadingIndicator
                show={isLoading}
            />
            <Container sx={{ minWidth: "300px", width: "100vw", margin: "auto", alignContent: "start", justifyContent: "start", justifyItems: "start", alignItems: "start", flexDirection: "row", display: "flex", padding: "0px !important", paddingTop: "0px", margin: "0px !important", maxWidth: "100vw !important" }}>
                <Card variant="outlined" sx={{ minWidth: "200px", width: "300px", margin: "0px", minHeight: "100vh" }}>
                    <SidebarAdmin />
                </Card>
                <Container sx={{ minWidth: "300px", width: "calc(100vw-300px)", margin: "0px", paddingTop: "10px",maxWidth:'unset !important' }}>

                    <div style={{ height: '70vh', width: '100%',maxWidth:'unset' }}>
                        <DataGrid
                            slots={{ toolbar: CustomToolbar }}
                            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}

                            sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    textAlign: "center"
                                },
                                "& .MuiDataGrid-cellContent": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    textAlign: "left"
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    // Forced to use important since overriding inline styles
                                    height: "unset !important"
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    // Forced to use important since overriding inline styles
                                    maxHeight: "168px !important"
                                }
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            rowSelection={false}

                        />
                    </div>
                </Container>
            </Container>
            <EditUserModal
                open={changeaddUserModal2}
                handleClose={() => {
                    dispatch(changeaddUserModal({
                        bank_detail: {

                        }
                    }))
                }}
            />
        </div>

    );
}
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{ allColumns: true, fileName: "users", utf8WithBom: true }}
            />
        </GridToolbarContainer>
    );
}
export default HomeViewAdmin;