import BankRequestsView from "./BankRequestsView";

function BankRequestsHome() {

    return (
        <div className="App">
            <BankRequestsView />
        </div>
    );
}

export default BankRequestsHome;