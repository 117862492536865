import { AccountCircle, Lock, Password } from "@mui/icons-material";
import { Avatar, Button, Card, CardContent, Container, Divider, FormControl, InputAdornment, TextField, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { useDispatch, useSelector } from "react-redux";
import { changeUserEmail, changeUserPassword, adminloginUser, setLoadingHidden, setLoadingScreen } from "../state/admin_login_state";
import ErrorIndicator from "../../common/views/components/ErrorIndicator";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";


function AdminLoginView() {

    const user = useSelector((state) => state.adminlogin.user)
    const errorMessage = useSelector((state) => state.adminlogin.errorMessage)
    const isLoading = useSelector((state) => state.adminlogin.isLoading)

    var dispatch = useDispatch()
    var navigator = useNavigate();

    function handleLogin() {
        dispatch(setLoadingScreen())
        dispatch(adminloginUser({ email: user.email, password: user.password })).unwrap().then(() => {
            dispatch(setLoadingHidden())
            navigator("/admin_home")
        })
    }

    return (

        <div className="App">

            <LoadingIndicator
                show={isLoading}
            />
            <AppBarComponent
                title={APP_NAME}
            />
            <br /><br />
            <Card sx={{ minWidth: "300px", width: "50vw", margin: "auto" }}>
                <CardContent>

                    <FormControl sx={{ textAlign: "left", m: 1, width: '80%' }}>
                        <ErrorIndicator
                            message={errorMessage}
                        />
                    </FormControl>
                    <FormControl sx={{ textAlign: "left", m: 1, width: '80%' }}>
                        <Typography variant="h6">
                            Admin 로그인
                        </Typography>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '80%' }}>

                        <TextField
                            required
                            id="email"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            label="Email"
                            defaultValue=""
                            value={user.email ?? ''}
                            onChange={(e) => {
                                dispatch(changeUserEmail(e.target.value))
                            }}
                            placeholder="Email을 입력해주세요."
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '80%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            id="password"
                            label="Password"
                            type="password"
                            defaultValue=""
                            value={user.password ?? ''}
                            onChange={(e) => {
                                dispatch(changeUserPassword(e.target.value))
                            }}
                            placeholder="비밀번호를 입력해주세요."

                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '80%', justifyContent: 'space-between', flexDirection: "row" }}>
                        <Link to="https://open.kakao.com/o/s5naSDdf" rel="noreferrer" target="_blank">
                            <Typography >
                                <b>비밀번호찾기는 관리자 카카오톡으로 문의해주세요.</b>
                            </Typography>
                        </Link>
                        <Button variant="contained" sx={{ width: '100px', float: 'right' }} onClick={() => {
                            handleLogin()
                        }} disableElevation>
                            로그인
                        </Button>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%', textAlign: "center" }}>
                        <center>
                            <Divider />
                        </center>
                    </FormControl>

                </CardContent>
            </Card>
        </div>

    );
}

export default AdminLoginView;