import axios from "axios";
import { ADD_REQUEST, GET_FAQ_ADMIN, GET_USERS_ADMIN, IMAGE_UPLOAD, baseUrl } from "../../common/repository/api_routes";


async function getUsers(token) {
    var response = await axios
        .get(baseUrl + GET_USERS_ADMIN.route, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function getSubmissions(token) {
    var response = await axios
        .get(baseUrl + "/admin/submissions", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function getBankApprovals(token) {
    var response = await axios
        .get(baseUrl + "/admin/user/bank/update/requests", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function bankApprove(id, token) {
    var response = await axios
        .get(baseUrl + "/admin/user/bank/update/requests/" + id + "/approve", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function blockUser(uid, token) {
    var response = await axios
        .get(baseUrl + "/admin/user" + "/" + uid + "/block", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}



async function unBlockUser(uid, token) {
    var response = await axios
        .get(baseUrl + "/admin/user" + "/" + uid + "/unblock", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}




async function uploadImage(file, token) {
    var response = await axios
        .post(baseUrl + IMAGE_UPLOAD.route, {
            image: file
        }, {
            headers: {
                "Authorization": "Bearer " + token,
                'Content-Type': 'multipart/form-data'
            }
        });

    return response.data;
}


async function getFaq(token) {
    var response = await axios
        .get(baseUrl + GET_FAQ_ADMIN.route, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function getRequests(token) {
    var response = await axios
        .get(baseUrl + ADD_REQUEST.route, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function savetFaq(title, content, token) {
    var response = await axios
        .post(baseUrl + GET_FAQ_ADMIN.route, {
            title: title,
            content: content
        }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function saveRequests(formData, token) {
    var response = await axios
        .post(baseUrl + ADD_REQUEST.route, formData, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function updateRequests(id, formData, token) {
    var response = await axios
        .put(baseUrl + ADD_REQUEST.route + "/" + id + "/update", formData, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function activeRequest(id, token) {
    var response = await axios
        .put(baseUrl + ADD_REQUEST.route + "/" + id + "/active",{}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function inactiveRequest(id, token) {
    var response = await axios
        .put(baseUrl + ADD_REQUEST.route + "/" + id + "/deactive", {}, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function deleteRequests(id, token) {
    var response = await axios
        .delete(baseUrl + ADD_REQUEST.route + "/" + id + "/delete", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function addPoints(id, amount, reason, type, token) {
    var response = await axios
        .post(baseUrl + "/admin/user/" + id + "/points/add", {
            amount: amount,
            reason: reason,
            type: type
        }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function updateFaq(id, title, content, token) {
    var response = await axios
        .put(baseUrl + GET_FAQ_ADMIN.route + "/" + id + "/update", {
            title: title,
            content: content
        }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function updateUser(id, formData, token) {
    var response = await axios
        .put(baseUrl + "/admin/user" + "/" + id + "/update", formData, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function deleteFaq(id, token) {
    var response = await axios
        .delete(baseUrl + GET_FAQ_ADMIN.route + "/" + id + "/delete", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}



async function deleteUser(uid, token) {
    var response = await axios
        .delete(baseUrl + "/admin/user" + "/" + uid + "/delete", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}



const adminhomeRepo = {
    getUsers,
    getFaq,
    savetFaq,
    updateFaq,
    deleteFaq,
    getRequests,
    uploadImage,
    saveRequests,
    updateRequests,
    deleteRequests,
    blockUser,
    unBlockUser,
    updateUser,
    deleteUser,
    getSubmissions,
    getBankApprovals,
    bankApprove,
    addPoints,
    activeRequest,
    inactiveRequest
}

export default adminhomeRepo

