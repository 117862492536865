import { Title } from "@mui/icons-material";
import { Box, Button, FormControl, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import { useDispatch, useSelector } from "react-redux";
import { changeFaqContent, changeFaqModalOpen, changeFaqTitle, getfaq, savefaq, setFormErrors, setLoadingHidden, setLoadingScreen, updatefaq } from "../state/admin_home_state";
import ErrorIndicator from "../../common/views/components/ErrorIndicator";
import { toast } from "react-toastify";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60vw",
    minHeight: "80vh",
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}


export function FaqModal({ open, handleClose }) {

    const faq = useSelector((state) => state.adminhome.faq)
    const errorMessage = useSelector((state) => state.adminhome.formErrors)

    var dispatch = useDispatch()

    var modules = {
        toolbar: [
            [{ 'color': [] }],
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    var formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'color'
    ];

    function handleSave() {
        dispatch(setLoadingScreen())
        if (faq.id == null) {
            dispatch(savefaq({ content: faq.content, title: faq.title })).unwrap().then(() => {
                dispatch(setLoadingHidden())
                dispatch(setFormErrors(""))
                toast.success("Saved FAQ Successfully")
                dispatch(changeFaqModalOpen())
                dispatch(getfaq({}))

            })
        } else {
            dispatch(updatefaq({ id:faq.id, content: faq.content, title: faq.title })).unwrap().then(() => {
                dispatch(setLoadingHidden())
                dispatch(setFormErrors(""))
                toast.success("Updated FAQ Successfully")
                dispatch(changeFaqModalOpen())
                dispatch(getfaq({}))

            })
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    FAQ
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <FormControl sx={{ textAlign: "left", m: 1, width: '80%' }}>
                        <ErrorIndicator
                            message={errorMessage}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '50%' }}>
                        {/* <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Title />
                                    </InputAdornment>
                                ),
                            }}
                            id="title"
                            label="Title"
                            type="text"
                            defaultValue=""
                            value={faq.title ?? ''}
                            onChange={(e) => {
                                dispatch(changeFaqTitle(e.target.value))
                            }}
                            placeholder="Enter Question or Title"

                        /> */}

                        <ReactQuill value={faq.title ?? ''}  onChange={(html) => {
                            dispatch(changeFaqTitle(html))
                        }} style={{ height: "100px", marginBottom: "60px" }} theme="snow" modules={modules} formats={formats} />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%' }}>
                        <ReactQuill value={faq.content} onChange={(html) => {
                            dispatch(changeFaqContent(html))
                        }} style={{ height: "300px", marginBottom: "60px" }} theme="snow" modules={modules} formats={formats} />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%' }}>

                        <Button variant="contained" sx={{ width: '100px', float: 'right' }} onClick={() => {
                            handleSave()
                        }} disableElevation>
                            {faq.id == null ? 'Save' : 'Update'}
                        </Button>
                    </FormControl>


                </Typography>
            </Box>
        </Modal>
    )
}