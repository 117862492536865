import { Button, Card, Container, Switch } from "@mui/material";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import SidebarAdmin from "../../common/views/components/SideBarAdmin";
import { useDispatch, useSelector } from "react-redux";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { DataGrid, GridColDef, GridToolbar, GridToolbarContainer, GridToolbarExport, GridValueGetterParams, koKR } from '@mui/x-data-grid';
import { activeRequests, changeFaqModalOpen, deleteFaq, deleteRequests, getfaq, getrequests, getusers, inactiveRequests, setCloneRequest, setFormErrors, setLoadingHidden, setLoadingScreen, setUpdateFaq, setUpdateRequest } from "../state/admin_home_state";
import { useEffect } from "react";
import { FaqModal } from "./FaqModal";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import { Add, Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { RequestModal } from "./RequestsModal";
import { Link, useNavigate } from "react-router-dom";


function RequestViewAdmin() {

    const requests = useSelector((state) => state.adminhome.requestList)
    const faqModalOpen = useSelector((state) => state.adminhome.faqModalOpen)
    const isLoading = useSelector((state) => state.adminhome.isLoading)
    var navigator = useNavigate()

    var dispatch = useDispatch()

    function handleDelete(id) {

        dispatch(setLoadingScreen())
        dispatch(deleteRequests(id)).unwrap().then(() => {
            dispatch(setLoadingHidden())
            dispatch(setFormErrors(""))
            toast.success("Deleted Request Successfully")
            dispatch(getrequests({}))

        })
    }

    function handActiveRequest(id) {

        dispatch(setLoadingScreen())
        dispatch(activeRequests({id})).unwrap().then(() => {
          
            dispatch(setFormErrors(""))
           
            toast.success("Active Successfully")
            dispatch(setLoadingScreen())
            dispatch(getrequests({})).unwrap().then(() => {
                dispatch(setLoadingHidden())
            })

        })
    }


    function handinactiveRequest(id) {

        dispatch(setLoadingScreen())
        dispatch(inactiveRequests({id})).unwrap().then(() => {
          
            dispatch(setFormErrors(""))
          
            toast.success("Inactive Successfully")
            dispatch(setLoadingScreen())
            dispatch(getrequests({})).unwrap().then(() => {
                dispatch(setLoadingHidden())
            })

        })
    }


    function initState() {

        dispatch(getusers({}))

        dispatch(getrequests({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {

            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);


    const columns = [
        { field: 'id', headerName: 'ID', width: 100, },
        {
            field: 'name', headerName: 'Name', width: 200, renderCell: (params) => {
                if (params.row.request != null) {
                    return <div>
                        <a style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => {

                            navigator("/place_detail", {
                                state: {
                                    place: params.row.request,
                                }
                            })
                        }} >{params.row.request.name}</a>
                    </div>
                }
            }
        },
        { field: 'main_keyword', headerName: 'Main Keyword', width: 200,hide:true },
        { field: 'sub_keywords', headerName: 'Sub Keywords', width: 200,hide:true },
        { field: 'place_url', headerName: 'Place URL', width: 200, },
        { field: 'searched_url', headerName: 'Search URL', width: 200, },
        { field: 'hashtags', headerName: 'Hashtags', width: 300, },
        { field: 'blogger_limit', headerName: 'Blog Limit', width: 100, },
        { field: 'image_download_summary', headerName: 'Images Used', width: 100, },
        // { field: 'due_date', headerName: 'Due Date', width: 100, },
        { field: 'email', headerName: 'Email', width: 150, },
        { field: 'blog_url', headerName: 'Blog URL', width: 300, },
        { field: 'verified_at', headerName: 'Verified At', width: 300, },
        { field: 'actuve', headerName: 'Active', width: 100,renderCell:(params)=>{
            if (params.row.request != null) {
                return <Switch checked={params.row.request.active??false} onChange={(e)=>{
                    if(params.row.request.active??false){
                        handinactiveRequest(params.row.request.id)
                    }else{
                        handActiveRequest(params.row.request.id)
                    }
                }} size="small" />
            }
        } },

        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 400,
            renderCell: (params) => {
                if (params.row.request != null) {
                    return <div>
                        <Button variant="contained" onClick={() => {
                            dispatch(setUpdateRequest(params.row.request))
                        }} color="warning"><Edit /> Edit</Button> &nbsp;
                        <Button variant="contained" onClick={() => {
                            dispatch(setCloneRequest(params.row.request))
                        }} color="primary"><Edit /> Duplicate</Button> &nbsp;
                        <Button variant="contained" color="error" onClick={() => {
                            handleDelete(params.row.request.id)
                        }} ><Delete /> Delete</Button>
                    </div>
                }
            }
        },
    ];

    var rows = [

    ];

    if (requests != null) {

        // console.log(requests)
        var indexnew = 1;
        (requests).forEach((request, index) => {
            rows.push({
                id: indexnew,
                // name: request.name,
                place_url: request.place_url,
                hashtags: request.hashtags,
                main_image: request.main_image,
                blogger_limit: request.blogger_limit,
                image_download_summary: request.image_download_summary,
                due_date: request.due_date,
                images: request.images,
                searched_url: request.searched_url,

                main_keyword: request.main_keyword, sub_keywords: request.sub_keywords, request: request
            })
            indexnew = indexnew + 1
            if (request.verified_submissions != null) {
                request.verified_submissions.forEach((submission) => {
                    rows.push({
                        id: indexnew,
                        email: submission.user.email,
                        blog_url: submission.url,
                        verified_at: getFormatedDate(submission.created_at)

                    })
                    indexnew = indexnew + 1
                })
            }
        })
    }

    return (

        <div>
            <LoadingIndicator
                show={isLoading}
            />
            <AppBarComponent
                title={APP_NAME}
                isAdmin={true}
            />

            <Container sx={{ minWidth: "300px",padding:"0px", width: "100vw", margin: "auto", alignContent: "start", justifyContent: "start", justifyItems: "start", alignItems: "start", flexDirection: "row", display: "flex", padding: "0px !important", paddingTop: "0px", margin: "0px !important", maxWidth: "100vw !important" }}>
                <Card variant="outlined" sx={{ minWidth: "200px", width: "300px", margin: "0px", minHeight: "100vh" }}>
                    <SidebarAdmin />
                </Card>
                <Container sx={{ minWidth: "300px", width: "calc(100vw-300px)", margin: "0px", paddingTop: "10px",maxWidth:'unset !important'  }}>

                    <Container sx={{ margin: 0, textAlign: "left" }}>
                        <Button type="button" onClick={() => {
                            dispatch(changeFaqModalOpen())
                        }} variant="contained" color="success"><Add /> Add New</Button>
                    </Container>
                    <br />
                    <div style={{ height: '70vh', width: '100%' }}>

                        <DataGrid
                            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                            slots={{ toolbar: CustomToolbar }}
                            sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    textAlign: "center"
                                },
                                "& .MuiDataGrid-cellContent": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    textAlign: "left"
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    // Forced to use important since overriding inline styles
                                    height: "unset !important"
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    // Forced to use important since overriding inline styles
                                    maxHeight: "168px !important"
                                }
                            }}
                            initialState={{
                                columns: {
                                  columnVisibilityModel: {
                                    main_keyword: false,
                                    sub_keywords: false,
                                    place_url: false,
                                    searched_url: false,
                                    hashtags: false,
                                    email: false,
                                  },
                                },
                              }}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            rowSelection={false}

                        />
                    </div>
                </Container>
            </Container>
            <RequestModal
                open={faqModalOpen}
                handleClose={() => {
                    dispatch(changeFaqModalOpen())
                }}
            />
        </div>

    );
}
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{ allColumns: true, fileName: "request_history", utf8WithBom: true }}
            />
        </GridToolbarContainer>
    );
}


function getFormatedDate(dateNow) {
    const date = new Date(dateNow)

    // Extract the individual components of the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the components into the desired format
    const formattedDate = `${year}.${month}.${day} ${hours}.${minutes}.${seconds}`;


    return formattedDate;
}



export default RequestViewAdmin;