import { toast } from "react-toastify";
import homeRepo from "../repository/home_repo";

async function allplaces() {
    var token = localStorage.getItem("token")
    var response = await homeRepo.getAllPlaces(token)

    return response;
}


async function appliedplaces() {
    var token = localStorage.getItem("token")
    var response = await homeRepo.appliedplaces(token)

    return response;
}

async function applyPlace(place) {
    var token = localStorage.getItem("token")
    var response = await homeRepo.applyPlace(place, token)

    return response;
}


async function downloadPlace(place) {
    var token = localStorage.getItem("token")
    var response = await homeRepo.downloadPlace(place, token)

    return response;
}


async function verifyPlace(place, url) {
    var token = localStorage.getItem("token")
    var response = await homeRepo.verifyPlace(place, url, token)

    return response;
}

async function completedplaces() {
    var token = localStorage.getItem("token")
    var response = await homeRepo.completedplaces(token)

    return response;
}

async function getProfile() {
    var token = localStorage.getItem("token")
    var response = await homeRepo.getprofile(token)

    return response;
}


async function getPoints() {
    var token = localStorage.getItem("token")
    var response = await homeRepo.getPoints(token)

    return response;
}


async function changeBankDetails(postData) {
    var token = localStorage.getItem("token")
    var response = await homeRepo.bankDetailsUpdate(postData, token)

    return response;
}


async function changePassword(postData) {
    var token = localStorage.getItem("token")
    var response = await homeRepo.passwordChange(postData, token)

    return response;
}



function validate_bank(bank_name, account_number, account_name, identification1, identification2) {

    if (bank_name == null || bank_name.trim() == "") {
        toast.error("은행 이름이 비어 있습니다.")
        return false
    }
    if (bank_name != null && !bank_name.match(/[a-zA-z\u3131-\uD79D]/ugi)) {
        toast.error("은행 이름은 숫자를 포함할 수 없습니다.")
        return false
    }

    if (account_number == null || account_number.trim() == "") {
        toast.error("은행 계좌 번호가 비어 있습니다.")
        return false
    }

    if (account_number != null && !account_number.match(/^[0-9]+$/)) {
        toast.error("은행 이름은 숫자만 포함할 수 있습니다.")
        return false
    }

    if (account_name == null || account_name.trim() == "") {
        toast.error("은행 계좌 이름이 비어 있습니다.")
        return false
    }

    if (account_name != null && !account_name.match(/[a-zA-z\u3131-\uD79D]/ugi)) {
        toast.error("은행 계좌 이름이 잘못되었습니다.")
        return false
    }

    if (identification1 != null && !identification1.match(/^[0-9]+$/)) {
        toast.error("식별 번호가 유효하지 않습니다")
        return false
    }

    if (identification2 != null && !identification2.match(/^[0-9]+$/)) {
        toast.error("식별 번호가 유효하지 않습니다")
        return false
    }

    if (identification1 == null || identification1.trim().length < 6) {
        toast.error("ID는 5자 이상이어야 합니다.")
        return false
    }

    if (identification2 == null || identification2.trim().length < 1) {
        toast.error("ID 2는 1자 이상이어야 합니다.")
        return false
    }

    return true
}


function validate_password(password, confirm_password) {


    if (password == null || password.trim() == "") {
        toast.error("비밀번호를 입력해주세요")
        return false
    }

    if (password.trim().length < 5) {
        toast.error("비밀번호는 5자 이상이어야 합니다.")
        return false
    }

    if (confirm_password != password) {
        toast.error("2개의 비밀번호가 일치하지 않습니다. 다시 입력해주세요")
        return false
    }

    return true
}



function validate_bank2(formData) {

    if (formData.bank_name == null || formData.bank_name.trim() == "") {
        toast.error("Bank Name is empty")
        return false
    }

    if (formData.account_number == null || formData.account_number.trim() == "") {
        toast.error("Bank Account No is empty")
        return false
    }

    if (formData.account_name == null || formData.account_name.trim() == "") {
        toast.error("Bank Account Name is empty")
        return false
    }

    if (formData.bank_name.trim().length < 5) {
        toast.error("Password need to contain atleast 5 characters")
        return false
    }

    return true
}

const homeServices = {
    allplaces,
    appliedplaces,
    completedplaces,
    applyPlace,
    verifyPlace,
    downloadPlace,
    getProfile,
    getPoints,
    changeBankDetails,
    changePassword,
    validate_bank,
    validate_password
}

export default homeServices