import axios from "axios";
import { LOGIN_API_ROUTE, baseUrl } from "../../common/repository/api_routes";


async function login(email, password) {
    var response = await axios
        .post(baseUrl + LOGIN_API_ROUTE.route, {
            email: email,
            password: password,
        });

    return response.data;
}

const loginRepo = {
    login
}

export default loginRepo

