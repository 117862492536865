import axios from "axios";
import { GET_FAQ_USER, baseUrl } from "../../common/repository/api_routes";


async function getFaq(token) {
    var response = await axios
        .get(baseUrl + GET_FAQ_USER.route, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

const faqRepo = {
    getFaq
}

export default faqRepo

