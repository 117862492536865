import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getprofile, loadallplaces, loadappliedplaces, loadcompletedplaces, setAddFormErrors, setLoadingScreen } from "../state/home_state";
import { Card, Container, IconButton, ImageList, ImageListItemBar, ListSubheader, Typography, Box, Toolbar } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ImageListItem, { imageListItemClasses } from "@mui/material/ImageListItem";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { IMG_URL } from "../../common/repository/api_routes";

import CssBaseline from '@mui/material/CssBaseline';
import AppBarComponent from "../../common/views/components/AppBarComponent";
import Sidebar from "../../common/views/components/SideBar";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";

function CompletedView() {
    const allplaces = useSelector((state) => state.home.completedplaces)
    const isLoading = useSelector((state) => state.home.isLoading)

    const [mobileOpen, setMobileOpen] = useState(false);

    const dispatch = useDispatch()
    const navigator = useNavigate()

    function initState() {
        dispatch(setAddFormErrors(""))
        dispatch(setLoadingScreen({}))
        dispatch(getprofile({}))
        dispatch(loadallplaces({}))
        dispatch(loadappliedplaces({}))
        dispatch(loadcompletedplaces({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {

            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerWidth = 300;

    const theme = createTheme({
        breakpoints: {
          values: {
            mobile: 0,
            bigMobile: 350,
            tablet: 650,
            desktop: 1000
          }
        }
    });

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBarComponent
                title={APP_NAME}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Sidebar
                drawerOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                
                <LoadingIndicator
                    show={isLoading}
                />

                <Box sx={{ padding: 0, }}>
                    <ThemeProvider theme={theme}>
                        <Box
                            sx={{
                                display: "grid",
                                marginBottom: 20,
                                gap: 2,
                                gridTemplateColumns: {
                                    mobile: "repeat(2, minmax(0, 1fr))",
                                    bigMobile: "repeat(2, minmax(0, 1fr))",
                                    tablet: "repeat(2, minmax(0, 1fr))",
                                    desktop: "repeat(4, minmax(0, 1fr))",
                                },
                                [`& .${imageListItemClasses.root}`]: {
                                    display: "flex",
                                    flexDirection: "column"
                                }
                            }}
                        >
                            {allplaces != null ? allplaces.map((item) => {
                                return (
                                    <div onClick={() => {
                                        navigator("/details", {
                                            state: {
                                                place: item.blog_request,
                                            }
                                        })
                                    }}>
                                        <ImageListItem key={item.img} sx={{ padding: 0, '.MuiImageListItem': {height: '100%'} }}>
                                            <Box
                                                component="img"
                                                src={`${IMG_URL + "/" + item.blog_request.main_image}`}
                                                alt={item.name}
                                                sx={{ height: { mobile: '120px', bigMobile: '120px', tablet: "250px", desktop: "250px" }, objectFit: 'cover', }}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                sx={{ padding: 0, '.MuiImageListItemBar-titleWrap': { padding: '8px' }, textAlign: 'left' }}
                                                title={
                                                    <Box>
                                                        <Typography sx={{ fontSize: 15, margin: 0, padding: 0, textAlign: 'left', }}>{item.blog_request.name}</Typography>

                                                        <IconButton
                                                            sx={{ color: 'rgba(255, 255, 255, 0.54)', padding: 0 }}
                                                            aria-label={`info about ${item.title}`}
                                                        >
                                                            <Typography style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>
                                                                {item.blog_request.applied_count}/{item.blog_request.blogger_limit}
                                                            </Typography>
                                                        </IconButton>
                                                    </Box>
                                                }
                                            />
                                        </ImageListItem></div>)
                            }) : <div></div>}
                        </Box>
                    </ThemeProvider>
                </Box>
            </Box>
        </Box>
    );
}

export default CompletedView;