import HomeViewAdmin from "./HomeView";

function AdminHome() {

    return (
        <div className="App">
            <HomeViewAdmin />
        </div>
    );
}

export default AdminHome;