import { AccountBalance, AccountBox, AccountCircle, CommentBank, Email, Lock, Message, Money, Note, NumbersOutlined, Phone, } from "@mui/icons-material";
import { Avatar, Button, Card, CardContent, Container, Divider, FormControl, Grid, InputAdornment, TextField, Typography } from "@mui/material"
import AppBarComponent from "../../common/views/components/AppBarComponent";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { useDispatch, useSelector } from "react-redux";
import { bankAccountSave, changeAccountName, changeAccountNumber, changeBankName, changeFormNo, changeIdentificationNo1, changeIdentificationNo2, changeUserEmail, changeUserPassword, setLoadingScreen } from "../state/register_state";
import { Form, Link, useNavigate } from "react-router-dom";


function RegisterViewStep2() {

    const user = useSelector((state) => state.register.user)
    const bank = useSelector((state) => state.register.bank)

    var dispatch = useDispatch()

    var navigator = useNavigate()

    function handleRegister() {

        dispatch(setLoadingScreen());
        dispatch(bankAccountSave({
            bank_name: bank.bank_name,
            account_number: bank.account_number,
            account_name: bank.account_name,
            identification1: bank.identification_number1,
            identification2: bank.identification_number2,
        })).unwrap().then(() => {
            navigator("/home")
        })

    }

    return (

        <div>
            <AppBarComponent
                title={APP_NAME}
            />
            <br /><br />
            <Card sx={{ minWidth: "300px", width: "60vw", margin: "auto" }}>
                <CardContent>

                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <FormControl sx={{ textAlign: "left", m: 1, width: '80%' }}>
                                        <Typography variant="h6">
                                            유저 정보
                                        </Typography>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '100%' }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="email"
                                            label="Email"
                                            type="email"
                                            defaultValue=""
                                            disabled={true}
                                            value={user.email ?? ''}
                                            onChange={(e) => {
                                            }}
                                            placeholder=""

                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, width: '100%' }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Phone />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="phone"
                                            label="Phone"
                                            type="text"
                                            defaultValue=""
                                            value={user.phone ?? ''}

                                            placeholder=""

                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, width: '100%' }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Note />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="blog_id"
                                            label="Blog ID"
                                            type="ematextil"
                                            defaultValue=""
                                            value={user.blog_id ?? ''}

                                            placeholder=""

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl sx={{ textAlign: "left", m: 1, width: '80%' }}>
                                        <Typography variant="h6">
                                            은행 정보
                                        </Typography>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '80%' }}>

                                        <TextField
                                            required
                                            id="bank_name"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountBalance />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label="은행이름"
                                            defaultValue=""
                                            value={bank.bank_name ?? ''}
                                            onChange={(e) => {
                                                const re = /^([\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|A-Z|a-z])*$/

                                                if ((e.target.value.match(re) || e.target.value == '')) {
                                                    dispatch(changeBankName(e.target.value))
                                                }
                                            }
                                            }
                                            placeholder="은행이름"
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '80%' }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <NumbersOutlined />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="account_number"
                                            label="계좌번호"
                                            type="text"
                                            defaultValue=""
                                            value={bank.account_number ?? ''}
                                            onChange={(e) => {
                                                dispatch(changeAccountNumber(e.target.value))
                                            }}
                                            placeholder="계좌번호"

                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, width: '80%' }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountBox />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="account_name"
                                            label="이름"
                                            type="text"
                                            defaultValue=""
                                            value={bank.account_name ?? ''}
                                            onChange={(e) => {
                                                const re = /^([\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|A-Z|a-z])*$/

                                                if ((e.target.value.match(re) || e.target.value == '')) {
                                                    dispatch(changeAccountName(e.target.value))
                                                }
                                            }
                                            }
                                            placeholder="이름"

                                        />
                                    </FormControl>
                                    <br />
                                    <FormControl sx={{ m: 1, width: '80%' }}>
                                        <Typography sx={{ textAlign: "left" }}><b>주민등록앞 6자리+성별</b></Typography>
                                    </FormControl>
                                    <br />
                                    <FormControl sx={{ m: 1, width: '80%', alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center" }}>

                                        <Grid container spacing={1}>
                                            <Grid item xs={4}>


                                                <TextField
                                                    width="100%"
                                                    required

                                                    inputProps={{ maxLength: 6 }}

                                                    id="identification1"
                                                    label=""
                                                    defaultValue=""
                                                    value={bank.identification_number1 ?? ''}
                                                    onChange={(e) => {
                                                        dispatch(changeIdentificationNo1(e.target.value))
                                                    }}
                                                    placeholder=""

                                                />


                                            </Grid>
                                            <Grid item xs={1} sx={{ alignSelf: "center" }}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={1.3}>

                                                <TextField
                                                    width="100%"
                                                    required

                                                    inputProps={{ maxLength: 1 }}

                                                    id="identification2"
                                                    label=""
                                                    defaultValue=""
                                                    value={bank.identification_number2 ?? ''}
                                                    onChange={(e) => {
                                                        dispatch(changeIdentificationNo2(e.target.value))
                                                    }}
                                                    placeholder=""

                                                />

                                            </Grid>
                                            <Grid item xs={2} sx={{ alignSelf: "center" }}>
                                                <p style={{ fontSize: 18, fontWeight: "bold" }}>
                                                    *******
                                                </p>
                                            </Grid>

                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <FormControl sx={{ m: 1, width: '80%' }}>
                        <Typography sx={{ textAlign: "left" }}>
                            <p>출금은 매주 일요일에 진행되며 계좌번호가 등록되지 않았거나 잘못되었을 경우 입금되지 않을 수 있으며 이에 대한 책임은 본인에게 있습니다.</p>
                        </Typography>
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '80%', justifyContent: 'end', flexDirection: "row" }}>


                        <Button variant="contained" sx={{ width: 'auto', float: 'right' }} onClick={() => {
                            navigator("/home")
                        }} disableElevation>
                            계좌정보 다음에 등록하기
                        </Button>
                        <div style={{ width: "20px" }}></div>
                        <Button variant="contained" sx={{ width: '100px', float: 'right' }} onClick={() => {
                            handleRegister()
                        }} disableElevation>
                            등록하기
                        </Button>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%', textAlign: "center" }}>
                        <center>
                            <Divider />
                        </center>
                    </FormControl>
                    <br />
                    <FormControl sx={{ m: 1, width: '80%', flexDirection: "row", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                        <Link to="/">
                            <Typography>
                                <b>로그인</b>
                            </Typography>
                        </Link>
                    </FormControl>

                </CardContent>
            </Card >
        </div >

    );
}

export default RegisterViewStep2;