import axios from "axios";
import { ALL_PLACES, GET_FAQ_USER, baseUrl } from "../../common/repository/api_routes";


async function getAllPlaces(token) {
    var response = await axios
        .get(baseUrl + ALL_PLACES.route, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function applyPlace(place, token) {
    var response = await axios
        .get(baseUrl + ALL_PLACES.route + "/" + place + "/apply", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function downloadPlace(place, token) {
    var response = await axios
        .get(baseUrl + ALL_PLACES.route + "/" + place + "/download", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function verifyPlace(place, url, token) {
    var response = await axios
        .post(baseUrl + ALL_PLACES.route + "/" + place + "/verify", { url }, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function appliedplaces(token) {
    var response = await axios
        .get(baseUrl + ALL_PLACES.route + "/applied", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function completedplaces(token) {
    var response = await axios
        .get(baseUrl + ALL_PLACES.route + "/completed", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function getprofile(token) {
    var response = await axios
        .get(baseUrl + "/account", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function bankDetailsUpdate(postData, token) {
    var response = await axios
        .post(baseUrl + "/account/bank/update", postData, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

async function passwordChange(postData, token) {
    var response = await axios
        .post(baseUrl + "/account/password/change", postData, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}


async function getPoints(token) {
    var response = await axios
        .get(baseUrl + "/points", {
            headers: {
                "Authorization": "Bearer " + token
            }
        });

    return response.data;
}

const homeRepo = {
    getAllPlaces,
    appliedplaces,
    completedplaces,
    applyPlace,
    getprofile,
    bankDetailsUpdate,
    passwordChange,
    verifyPlace,
    downloadPlace,
    getPoints
}

export default homeRepo

