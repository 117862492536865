import SubmissionsView from "./SubmissionsView";

function SubmissionHome() {

    return (
        <div className="App">
            <SubmissionsView />
        </div>
    );
}

export default SubmissionHome;