import { AppBar, Box, IconButton, InputBase, Toolbar, Typography, alpha, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';

function AppBarComponent({ title, handleDrawerToggle, drawerWidth = 300,isAdmin=false }) {
  return (
    <AppBar position={isAdmin?"relative":"absolute"}
      sx={{
        width: { md: `calc(100% - ${isAdmin?0:drawerWidth}px)` },
        ml: { md: `${isAdmin?0:drawerWidth}px` },
      }}
    >
      <Toolbar>
        {
        (localStorage.getItem("token") != null || localStorage.getItem("admin_token") != null) ?

          (<IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>) : (<></>)
        }

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'block' } }}
        >
          {title ?? ''}
        </Typography>

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block', md: 'none' } }}
        >
          리뷰나라 기자단
        </Typography>

        {/* <Typography
          variant="h6"
          noWrap
          onClick={() => {
            localStorage.clear()
            window.top.location = "/"
          }}
          component="div"
          sx={{ flexGrow: 0, display: { xs: 'block', sm: 'block' } }}
        >
          {localStorage.getItem("token") == null && localStorage.getItem("admin_token") == null ? "" : "로그 아웃"}
        </Typography> */}
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;
