import { Email, Lock, Note, Numbers, Phone, Place, TextFields, Title } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import { useDispatch, useSelector } from "react-redux";
import { changeFaqContent, changeFaqModalOpen, changeFaqTitle, changeUserBankAccName, changeUserBankIdNo, changeUserBankName, changeUserBankNo, changeUserBlogId, changeUserEmailAddress, changeUserPasswordNew, changeUserPhone, getfaq, getusers, savefaq, setFormErrors, setLoadingHidden, setLoadingScreen, updateUserNew, updatefaq } from "../state/admin_home_state";
import ErrorIndicator from "../../common/views/components/ErrorIndicator";
import { toast } from "react-toastify";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50vw",
    minHeight: "40vh",
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}


export function EditUserModal({ open, handleClose }) {

    const selectedUser = useSelector((state) => state.adminhome.selectedUser)
    const errorMessage = useSelector((state) => state.adminhome.formErrors)

    var dispatch = useDispatch()


    function handleSave() {
        dispatch(setLoadingScreen())
        if (selectedUser.password == null || selectedUser.password == '') {
            dispatch(updateUserNew({
                id: selectedUser.id, formData: {
                    email: selectedUser.email,
                    phone: selectedUser.phone,
                    blog_id: selectedUser.blog_id,
                    account_name: selectedUser.bank_detail.account_name,
                    account_number: selectedUser.bank_detail.account_number,
                    bank_name: selectedUser.bank_detail.bank_name,
                    identification_number: selectedUser.bank_detail.identification_number,
                }
            })).unwrap().then(() => {
                dispatch(setLoadingHidden())
                dispatch(getusers({}))
                handleClose();
            })
        } else {
            dispatch(updateUserNew({
                id: selectedUser.id, formData: {
                    email: selectedUser.email,
                    phone: selectedUser.phone,
                    blog_id: selectedUser.blog_id,
                    password: selectedUser.password,
                    account_name: selectedUser.bank_detail.account_name,
                    account_number: selectedUser.bank_detail.account_number,
                    bank_name: selectedUser.bank_detail.bank_name,
                    identification_number: selectedUser.bank_detail.identification_number,
                }
            })).unwrap().then(() => {
                dispatch(setLoadingHidden())
                dispatch(getusers({}))
                handleClose();
            })
        }

    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    User
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <FormControl sx={{ textAlign: "left", m: 1, width: '80%' }}>
                       
                    </FormControl>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <TextField
                                    width="100%"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Email />
                                            </InputAdornment>
                                        ),
                                    }}
                                    id="email"
                                    label="Email"
                                    type="email"
                                    defaultValue=""
                                    value={selectedUser.email ?? ''}
                                    onChange={(e) => {
                                        dispatch(changeUserEmailAddress(e.target.value))
                                    }}
                                    placeholder=""

                                />
                            </FormControl>

                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <TextField
                                    width="100%"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        ),
                                    }}
                                    id="password"
                                    label="Password"
                                    type="password"
                                    defaultValue=""
                                    value={selectedUser.password ?? ''}
                                    onChange={(e) => {
                                        dispatch(changeUserPasswordNew(e.target.value))
                                    }}
                                    placeholder=""

                                />
                            </FormControl>

                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <TextField
                                    width="100%"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Phone />
                                            </InputAdornment>
                                        ),
                                    }}
                                    id="phone"
                                    label="Phone"
                                    type="text"
                                    defaultValue=""
                                    value={selectedUser.phone ?? ''}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/

                                        if (e.target.value.match(re) || e.target.value == '') {
                                            dispatch(changeUserPhone(e.target.value))
                                        }
                                    }
                                    }
                                    placeholder=""

                                />
                            </FormControl>

                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <TextField
                                    width="100%"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Note />
                                            </InputAdornment>
                                        ),
                                    }}
                                    id="blog_id"
                                    label="Blog ID"
                                    type="ematextil"
                                    defaultValue=""
                                    value={selectedUser.blog_id ?? ''}
                                    onChange={(e) => {
                                        dispatch(changeUserBlogId(e.target.value))
                                    }}
                                    placeholder=""

                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <TextField
                                    width="100%"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Place />
                                            </InputAdornment>
                                        ),
                                    }}
                                    id="bank_name"
                                    label="Bank Name"
                                    type="text"
                                    defaultValue=""
                                    value={selectedUser.bank_detail != null ? (selectedUser.bank_detail.bank_name ?? '') : ''}
                                    onChange={(e) => {
                                        const re = /^([\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|A-Z|a-z])*$/

                                        if ((e.target.value.match(re) || e.target.value == '')) {
                                            dispatch(changeUserBankName(e.target.value))
                                        }
                                    }}
                                    placeholder=""

                                />
                            </FormControl>

                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <TextField
                                    width="100%"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Numbers />
                                            </InputAdornment>
                                        ),
                                    }}
                                    id="account_no"
                                    label="Account No"
                                    type="text"
                                    defaultValue=""
                                    value={selectedUser.bank_detail != null ? (selectedUser.bank_detail.account_number ?? '') : ''}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/

                                        if ((e.target.value.match(re) || e.target.value == '')) {
                                            dispatch(changeUserBankNo(e.target.value))
                                        }
                                    }
                                    }
                                    placeholder=""

                                />
                            </FormControl>

                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <TextField
                                    width="100%"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TextFields />
                                            </InputAdornment>
                                        ),
                                    }}
                                    id="account_name"
                                    label="Account Name"
                                    type="text"
                                    defaultValue=""
                                    value={selectedUser.bank_detail != null ? (selectedUser.bank_detail.account_name ?? '') : ''}
                                    onChange={(e) => {
                                        const re = /^([\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|A-Z|a-z])*$/

                                        if ((e.target.value.match(re) || e.target.value == '')) {
                                            dispatch(changeUserBankAccName(e.target.value))
                                        }
                                    }}
                                    placeholder=""

                                />
                            </FormControl>


                            <FormControl sx={{ m: 1, width: '100%' }}>
                                <TextField
                                    width="100%"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TextFields />
                                            </InputAdornment>
                                        ),
                                    }}
                                    id="identification"
                                    label="Identification No"
                                    type="text"
                                    defaultValue=""
                                    value={selectedUser.bank_detail != null ? (selectedUser.bank_detail.identification_number ?? '') : ''}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/

                                        if ((e.target.value.match(re) || e.target.value == '') && e.target.value.length < 8) {
                                            dispatch(changeUserBankIdNo(e.target.value))
                                        }
                                    }
                                    }
                                    placeholder=""

                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <FormControl sx={{ m: 1, width: '100%' }}>

                        <Button variant="contained" sx={{ width: '100px', float: 'right' }} onClick={() => {
                            handleSave()
                        }} disableElevation>
                            Update
                        </Button>
                    </FormControl>


                </Typography>
            </Box>
        </Modal>
    )
}