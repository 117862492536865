import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { changeAccountName, changeAccountNumber, changeBankDetails, changeBankName, changeIdentificationNo1, changeIdentificationNo2, changePasswordNew, changeconfirmpassword, changeoldpassword, changepassword, getprofile, setAddFormErrors, setLoadingHidden, setLoadingScreen } from "../state/home_state";
import { bankAccountSave } from "../../register/state/register_state";
import { AccountBalance, AccountBox, AccountCircle, Lock, Message, NumbersOutlined, Phone } from "@mui/icons-material";
import { Button, Card, CardContent, Box, Divider, FormControl, Grid, Toolbar, InputAdornment, TextField, Typography } from "@mui/material"
import { APP_NAME } from "../../common/constants/ConfigApp";

import AppBarComponent from "../../common/views/components/AppBarComponent";
import Sidebar from "../../common/views/components/SideBar";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import ErrorIndicator from "../../common/views/components/ErrorIndicator";
import CssBaseline from '@mui/material/CssBaseline';

function MyInfoView() {
    const user = useSelector((state) => state.home.profile)
    const password = useSelector((state) => state.home.password)
    const bank = useSelector((state) => state.home.bank_details)
    const bankDetailsLoaded = useSelector((state) => state.home.bankDetailsLoaded)
    const isLoading = useSelector((state) => state.home.isLoading)
    const errorMessage = useSelector((state) => state.home.formErrors)

    const [mobileOpen, setMobileOpen] = useState(false);

    const dispatch = useDispatch()

    function handleChangePassword() {
        dispatch(setLoadingScreen());

        dispatch(changePasswordNew({
            current_password: password.oldPassword,
            new_password: password.newPassword,
            confirm_password: password.confirmPassword,
        })).unwrap().then(() => {
            toast.success("성공적으로 비밀번호 변경")
            dispatch(setAddFormErrors(""));
            dispatch(getprofile({}));
        }).catch(() => {
            dispatch(setLoadingHidden());
        })
    }
    function handleSaveUpdate() {
        dispatch(setLoadingScreen());

        if (bankDetailsLoaded) {
            dispatch(bankAccountSave({
                bank_name: bank.bank_name,
                account_number: bank.account_number,
                account_name: bank.account_name,
                identification1: bank.identification_number1,
                identification2: bank.identification_number2,
            })).unwrap().then(() => {
                toast.success("성공적으로 업데이트")
                dispatch(setAddFormErrors(""))
                dispatch(getprofile({}));
            }).catch(() => {
                dispatch(setLoadingHidden());
            })
        } else {
            dispatch(changeBankDetails({
                postData: {
                    bank_name: bank.bank_name,
                    account_number: bank.account_number,
                    account_name: bank.account_name,
                    identification1: bank.identification_number1,
                    identification2: bank.identification_number2,
                    identification_number: bank.identification_number,
                }
            })).unwrap().then(() => {
                toast.success("승인을 위해 성공적으로 전송됨")
                dispatch(setAddFormErrors(""))
                dispatch(getprofile({}));
            }).catch(() => {
                dispatch(setLoadingHidden());
            })
        }
    }

    function initState() {
        dispatch(setAddFormErrors(""))
        dispatch(setLoadingScreen({}))
        dispatch(getprofile({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {
            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerWidth = 300;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBarComponent
                title={APP_NAME}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Sidebar
                drawerOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                
                <LoadingIndicator
                    show={isLoading}
                />

                <ErrorIndicator
                    message={errorMessage}
                />

                <Box sx={{ padding: 0, }}>
                    <Grid container gap={1}>
                        <Grid xs={12} sm={12} md={5}>
                            <Card >
                                <CardContent>
                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <TextField
                                            required
                                            id="email"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircle />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            type="email"
                                            label="이메일"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            defaultValue=""
                                            disabled={true}
                                            value={user.email ?? ''}
                                            placeholder="이메일을 입력하세요"
                                        />
                                    </FormControl>
                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="password"
                                            label="비밀번호"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            type="password"
                                            defaultValue=""
                                            value={password.oldPassword ?? ''}
                                            onChange={(e) => {
                                                dispatch(changeoldpassword(e.target.value))
                                            }}
                                            placeholder="비밀번호를 입력하세요"

                                        />
                                    </FormControl>

                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="new_password"
                                            label="새 비밀번호"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            type="password"
                                            defaultValue=""
                                            value={password.newPassword ?? ''}
                                            onChange={(e) => {
                                                dispatch(changepassword(e.target.value))
                                            }}
                                            placeholder="비밀번호를 입력하세요"

                                        />
                                    </FormControl>

                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="confirm_password"
                                            label="비밀번호 확인"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            type="password"
                                            defaultValue=""
                                            value={password.confirmPassword ?? ''}
                                            onChange={(e) => {
                                                dispatch(changeconfirmpassword(e.target.value))
                                            }}
                                            placeholder="비밀번호를 다시 입력하세요"

                                        />
                                    </FormControl>


                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Phone />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="phone"
                                            type="number"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            label="핸드폰번호"
                                            defaultValue=""
                                            value={user.phone ?? ''}
                                            disabled={true}
                                            placeholder="핸드폰번호"

                                        />
                                    </FormControl>

                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Message />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="blog_id"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            label="Blog 주소"
                                            defaultValue=""
                                            value={user.blog_id ?? ''}
                                            disabled={true}
                                            placeholder="Blog 주소"

                                        />

                                        <Typography sx={{ textAlign: "left" }}>
                                            <p>아래의 Blog 주소 형식에 맞게 입력해주세요</p>
                                        </Typography>
                                        <Typography sx={{ textAlign: "left" }}>
                                            <span>https://blog.naver.com/blogid</span>
                                        </Typography>
                                    </FormControl>

                                    <FormControl sx={{ m: 1, width: '100%', justifyContent: 'center', flexDirection: "row" }}>

                                        <Typography >
                                            <b></b>
                                        </Typography>

                                        <Button variant="contained" sx={{ width: '150px', float: 'right' }} onClick={() => {
                                            handleChangePassword()
                                        }} disableElevation>
                                            비밀번호 변경
                                        </Button>
                                    </FormControl>



                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid xs={12} sm={12} md={5}>
                            <Card >
                                <CardContent>
                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>

                                        <TextField
                                            required
                                            id="bank_name"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountBalance />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label="은행이름"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            defaultValue=""
                                            value={bank.bank_name ?? ''}
                                            onChange={(e) => {
                                                const re = /^([\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|A-Z|a-z])*$/

                                                if ((e.target.value.match(re) || e.target.value == '')) {
                                                    dispatch(changeBankName(e.target.value))
                                                }
                                            }
                                            }
                                            placeholder="은행이름"
                                        />
                                    </FormControl>
                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <NumbersOutlined />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="account_number"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            label="계좌번호"
                                            type="text"
                                            defaultValue=""
                                            value={bank.account_number ?? ''}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/

                                                if ((e.target.value.match(re) || e.target.value == '')) {
                                                    dispatch(changeAccountNumber(e.target.value))
                                                }
                                            }
                                            }
                                            placeholder="계좌번호"

                                        />
                                    </FormControl>

                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <TextField
                                            width="100%"
                                            required
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountBox />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            id="account_name"
                                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                            label="이름"
                                            type="text"
                                            defaultValue=""
                                            value={bank.account_name ?? ''}
                                            onChange={(e) => {
                                                const re = /^([\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3|A-Z|a-z])*$/

                                                if ((e.target.value.match(re) || e.target.value == '')) {
                                                    dispatch(changeAccountName(e.target.value))
                                                }
                                            }}
                                            placeholder="이름"

                                        />
                                    </FormControl>
                                    <br />
                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <Typography sx={{ textAlign: "left" }}><b>주민등록앞 6자리+성별</b></Typography>
                                    </FormControl>
                                    <br />
                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', alignItems: "center", alignContent: "center", justifyItems: "center", justifyContent: "center" }}>

                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    inputProps={{ maxLength: 6 }}
                                                    sx={{ '.MuiInputBase-input': { fontSize: '14px' }, width: "-webkit-fill-available" }}
                                                    id="identification1"
                                                    label=""
                                                    defaultValue=""
                                                    value={bank.identification_number1 ?? ''}
                                                    onChange={(e) => {
                                                        const re = /^[0-9\b]+$/

                                                        if ((e.target.value.match(re) || e.target.value == '')) {
                                                            dispatch(changeIdentificationNo1(e.target.value))
                                                        }
                                                    }}
                                                    placeholder=""
                                                />
                                            </Grid>
                                            <Grid item xs={1} sx={{ alignSelf: "center" }}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    width="100%"
                                                    required
                                                    inputProps={{ maxLength: 1 }}
                                                    id="identification2"
                                                    label=""
                                                    sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                                                    defaultValue=""
                                                    value={bank.identification_number2 ?? ''}
                                                    onChange={(e) => {
                                                        const re = /^[0-9\b]+$/

                                                        if ((e.target.value.match(re) || e.target.value == '')) {
                                                            dispatch(changeIdentificationNo2(e.target.value))
                                                        }
                                                    }}
                                                    placeholder=""

                                                />
                                            </Grid>
                                            <Grid item xs={2} sx={{ alignSelf: "center" }}>
                                                <p style={{ fontSize: 18, fontWeight: "bold" }}>
                                                    *******
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', }}>
                                        <Typography sx={{ textAlign: "left" }}>
                                            <p>출금은 매주 일요일에 진행되며 계좌번호가 등록되지 않았거나 잘못되었을 경우 입금되지 않을 수 있으며 이에 대한 책임은 본인에게 있습니다.</p>
                                        </Typography>
                                    </FormControl>

                                    <FormControl sx={{ marginBottom: 2, width: '100%', paddingLeft: '0', justifyContent: 'center', flexDirection: "row" }}>
                                        <Typography >
                                            <b></b>
                                        </Typography>

                                        <Button variant="contained" sx={{ width: '100px' }} onClick={() => {
                                            handleSaveUpdate()
                                        }} disableElevation>
                                            등록하기
                                        </Button>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

export default MyInfoView;