import FAQViewAdmin from "./FAQView";

function FAQHome() {

    return (
        <div className="App">
            <FAQViewAdmin />
        </div>
    );
}

export default FAQHome;