import axios from "axios";
import { ADMIN_LOGIN_API_ROUTE, baseUrl } from "../../common/repository/api_routes";


async function adminlogin(email, password) {
    var response = await axios
        .post(baseUrl + ADMIN_LOGIN_API_ROUTE.route, {
            email: email,
            password: password,
        });

    return response.data;
}

const adminloginRepo = {
    adminlogin
}

export default adminloginRepo

