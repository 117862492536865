import FaqView from "./FaqView";

function Faq() {

    return (
        <div className="App">
            <FaqView />
        </div>
    );
}

export default Faq;