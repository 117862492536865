import { CircularProgress } from "@mui/material";

function LoadingIndicator({ show }) {
    return (
        show ? <div style={{ zIndex: 9999, backgroundColor: "rgba(0,0,0,0.3", position: "fixed", width: "100vw", height: "100vh", left: 0, top: 0 }}>
            <div style={{ height: "100%", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "center" }}>
                <CircularProgress color="inherit" />

            </div>
        </div> : <div></div>
    );
}

export default LoadingIndicator;