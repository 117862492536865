import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";


function Layout() {

  return (
    <>
      <Outlet />

       <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="colored"
            /> 
    </>
  )
}

export default Layout;
