import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { applyplace, changeApiErrorMessage, changeverifyModalOpen, setAddFormErrors, setLoadingHidden, setLoadingScreen } from "../state/home_state";
import { Button, Box, Toolbar, Container, Grid, Typography } from "@mui/material"
import { APP_NAME } from "../../common/constants/ConfigApp";
import { IMG_URL, baseUrl, downloadUrl, } from "../../common/repository/api_routes";
import { VerifyModal } from "./VerifyModal";
import { AlertMessage } from "./AlertMessage";

import AppBarComponent from "../../common/views/components/AppBarComponent";
import Sidebar from "../../common/views/components/SideBar";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import CssBaseline from '@mui/material/CssBaseline';
import axios from "axios";

function PlaceDetailView() {

    const verifyModalOpen = useSelector((state) => state.home.verifyModalOpen)
    const isLoading = useSelector((state) => state.home.isLoading)
    const apiErrorMessage = useSelector((state) => state.home.apiErrorMessage)

    const [mobileOpen, setMobileOpen] = useState(false);

    const dispatch = useDispatch()
    const navigator = useNavigate()

    function applyPlace(placeId) {
        dispatch(setLoadingScreen())
        dispatch(applyplace({ place: placeId })).unwrap().then(() => {
            dispatch(setLoadingHidden())
            dispatch(setAddFormErrors(""))
            toast.success('성공적으로 적용')
            navigator("/applied")
        })
    }

    function initState() {
        dispatch(setAddFormErrors(""))
    }



    const downloadAndOpenUrl = async (baseUrl, placeId) => {

        var token = localStorage.getItem("token")
        try {
            // Send the GET request to the backend
            const response = await axios.get(`${baseUrl}/place/${placeId}/download`,  {
                headers: {
                    "Authorization": "Bearer " + token
                }
            });

            // Check if the response contains the URL
            if (response.data ) {
                const downloadUrl2 = response.data;
                // Open the URL in a new tab
                window.top.location.href=downloadUrl+"/"+(downloadUrl2);
            } else {
                console.error('No URL found in the response');
            }
        } catch (error) {
            console.error('Error fetching the URL:', error);
        }
    };

    useEffect(() => {
        if (document.readyState === 'complete') {
            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }

        window.onbeforeunload = function () {
            dispatch(setLoadingScreen());
        };

        window.onblur = function () {
            dispatch(setLoadingHidden());
        };
    }, []);

    const location = useLocation();

    // get userId
    let place = location.state.place;

    var usernew = JSON.parse(localStorage.getItem("user"))
    var isBlogApplied = false

    var date = new Date();

    const options = {
        timeZone: 'Asia/Seoul',
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };
    const koreanDate = date.toLocaleString('en-US', options);

    var today = koreanDate.split('/')[2] + '-' + koreanDate.split('/')[0] + '-' + koreanDate.split('/')[1];

    var applyCount = place.bloggers.filter((el) => {
        var cdate = new Date(el.created_at);

        const options = {
            timeZone: 'Asia/Seoul',
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        const koreanCDate = cdate.toLocaleString('en-US', options);

        var createdDate = koreanCDate.split('/')[2] + '-' + koreanCDate.split('/')[0] + '-' + koreanCDate.split('/')[1];

        return (el.user_id == usernew[0]['id'] && createdDate == today);
    });

    if (applyCount.length > 0) {
        isBlogApplied = true
    }

    var isBlogVerified = false

    var verifyCount = place.verified_submissions.filter((el) => {
        var cdate = new Date(el.created_at);

        const options = {
            timeZone: 'Asia/Seoul',
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        const koreanCDate = cdate.toLocaleString('en-US', options);

        var createdDate = koreanCDate.split('/')[2] + '-' + koreanCDate.split('/')[0] + '-' + koreanCDate.split('/')[1];

        return (el.user_id == usernew[0]['id'] && createdDate == today);
    });

    if (verifyCount.length > 0) {
        isBlogVerified = true
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerWidth = 300;

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <CssBaseline />

            <AppBarComponent
                title={APP_NAME}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Sidebar
                drawerOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
            />

            <AlertMessage
                open={(apiErrorMessage != null && apiErrorMessage != "")}
                handleClose={() => {
                    dispatch(changeApiErrorMessage(""))
                }}
                message={apiErrorMessage}
            />

            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, maxWidth: '100%', width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />

                <LoadingIndicator
                    show={isLoading}
                />

                <Box sx={{ margin: "20px", padding: 0, }}>
                    <Grid container gap={5}>
                        <Grid item xs={12} sm={12} md={3}>
                            <Container sx={{ textAlign: "center", width: "100%" }} >
                                <img style={{ width: "100px", height: "100px" }} src={IMG_URL + "/" + place.main_image} />
                            </Container>
                            <Container sx={{ textAlign: "center", width: "100%" }} >
                                {place.name}
                            </Container>
                            <Container sx={{ textAlign: "center", width: "100%" }}  >
                                {place.applied_count}/{place.blogger_limit}
                            </Container>

                            <br />

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    {isBlogApplied ? <Button style={{ width: "100%", backgroundColor: isBlogVerified ? "#5A5A5A" : "", borderColor: isBlogVerified ? "#5A5A5A" : "" }} onClick={() => {
                                        var user = JSON.parse(localStorage.getItem("user"))

                                        if (isBlogVerified) {
                                            //toast.error("이미 확인")
                                        } else {
                                            if (place.bloggers.filter((el) => el.user_id = user[0]['id']).length > 0) {
                                                dispatch(changeverifyModalOpen())
                                            } else {
                                                /// applyPlace(place.id)
                                            }
                                        }
                                    }} variant="contained" color={isBlogVerified ? "info" : "info"}>{isBlogVerified ? '완료' : 'URL 올리기'}</Button> : <Button style={{ width: "100%" }} onClick={() => {
                                        var user = JSON.parse(localStorage.getItem("user"))

                                        var date = new Date();

                                        const options = {
                                            timeZone: 'Asia/Seoul',
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                        };
                                        const koreanDate = date.toLocaleString('en-US', options);

                                        var today = koreanDate.split('/')[2] + '-' + koreanDate.split('/')[0] + '-' + koreanDate.split('/')[1];
                                        var alreadyApplied = place.bloggers.filter((el) => {
                                            var cdate = new Date(el.created_at);

                                            const options = {
                                                timeZone: 'Asia/Seoul',
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                            };
                                            const koreanCDate = cdate.toLocaleString('en-US', options);

                                            var createdDate = koreanCDate.split('/')[2] + '-' + koreanCDate.split('/')[0] + '-' + koreanCDate.split('/')[1];

                                            return (el.user_id == user[0]['id'] && createdDate == today);
                                        });

                                        if (alreadyApplied.length > 0) {
                                            toast.error("이미 신청하셨습니다")
                                        } else {
                                            applyPlace(place.id)
                                        }
                                    }} variant="contained" color="info">신청하기</Button>}
                                </Grid>

                                <Grid item xs={6}>
                                    <Button disabled={!isBlogApplied} onClick={() => {
                                        if (isBlogApplied) {
                                           // window.top.location.href = baseUrl + "/place/" + place.id + "/download";
                                            downloadAndOpenUrl(baseUrl,place.id)
                                        }
                                    }} style={{ width: "100%" }} variant="contained" color="warning">이미지받기</Button>
                                </Grid>
                            </Grid>

                            <br />

                            <Container style={{ color: "red", textAlign: "center", width: "100%", margin: "0px", padding: "0px", fontSize: 14 }} >
                                신청한 블로거만<br />
                                이미지를 다운받을 수 있습니다<br /><br />

                                신청 후 24시간내에 URL을 올려야합니다.<br />
                                24시간 후에는 URL을 올릴 수 없습니다.
                            </Container>

                            <Typography sx={{ color: "red", textAlign: "center", width: "100%" }} variant="p"></Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8}>
                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>메인 키워드 : 블로그 내용 본문에 반드시 3번이상 들어가야 합니다.</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                {place.main_keyword} <span style={{ color: "red" }}>(띄어쓰기까지 반드시 일치해야 합니다)</span>
                            </Typography>
                            <br />
                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>서브 키워드 : 블로그 내용 본문에 반드시 1번이상씩 모든 서브키워드가 들어가야합니다.</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                {place.sub_keywords} <span style={{ color: "red" }}>(띄어쓰기까지 반드시 일치해야 합니다)</span>
                            </Typography>

                            <br />

                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>해시태그</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                {place.hashtags.split(",").map((e) => {
                                    return '#' + e.replaceAll(",", " ")
                                }).toString().replaceAll(",", " ")} <span style={{ color: "red" }}>(띄어쓰기까지 반드시 일치해야 합니다)</span>
                            </Typography>

                            <br />

                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>플레이스 이름 및 주소</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left", overflowWrap: 'break-word' }}>
                                {place.name}
                                <br />
                                <a style={{ color: "blue" }} href={place.place_url} rel="noreferrer" target="_blank">{place.place_url}</a>
                            </Typography>

                            <br />

                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>제목 및 작성내용 (글자수 및 이미지 갯수)</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                제목 및 작성내용은 자유롭게 해주시면 되고 글자수는 250글자, 이미지는 5개이상이어야합니다.
                            </Typography>

                            <br />

                            <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                                작성 주의사항
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                블로그 제목은 창의적으로 20글자이상 최대한 길게 써주셔야합니다.
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                내용을 다른 블로그 또는 다른 곳에서 복사하거나 비슷하게 사용하면 절대 안됩니다.
                            </Typography>
                            <Typography sx={{ textAlign: "left", color: "red" }}>
                                (블로그 포스팅이 누락되거나 저품질이 되면 URL이 승인되더라도 대금은 지급되지 않을 수 있습니다)
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                누락여부는 아래 사이트에서 확인하실 수 있습니다
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                <a style={{ color: "blue" }} href="https://whereispost.com/" rel="noreferrer" target="_blank">https://whereispost.com/</a>
                            </Typography>

                            <br />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography sx={{ textAlign: "left", color: "red" }}>
                                <b>※검수 주의사항</b>
                            </Typography>

                            <img src="process_img.png" style={{ width: "100%", height: "auto" }} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <VerifyModal
                open={verifyModalOpen}
                placeId={place.id}
                placeName={place.name}
                handleClose={() => {
                    dispatch(changeverifyModalOpen())
                }}
            />
        </Box>
    );
}

export default PlaceDetailView;