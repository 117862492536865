import axios from "axios";
import { REGISTER_API_ROUTE, baseUrl } from "../../common/repository/api_routes";


async function register(email, password, phone, blog_id) {
    var response = await axios
        .post(baseUrl + REGISTER_API_ROUTE.route, {
            email: email,
            password: password,
            phone: phone,
            blog_id: blog_id,
        });

    return response.data;
}

async function bankAccountSave(bank_name, account_number, account_name, identification1, identification2) {
    var response = await axios
        .post(baseUrl + "/account/bank", {
            bank_name: bank_name,
            account_number: account_number,
            account_name: account_name,
            identification_number: identification1 + identification2,
        }, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        });

    return response.data;
}

const registerRepo = {
    register,
    bankAccountSave
}

export default registerRepo

