import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import homeServices from '../services/home_services'
import { useNavigate } from 'react-router-dom'
import { goToLogin } from '../../common/repository/api_routes'




export const homeStatus = {
    INTIAL: 'INTIAL',
}

export const homeSlice = createSlice(
    {
        name: 'home',
        initialState: {
            user: {
                email: null,
                password: null
            },
            profile: {},
            bank_details: {
                bank_name: null,
                account_number: null,
                account_name: null,
                identification_number: null,
                identification_number1: null,
                identification_number2: null
            },
            password: {
                oldPassword: null,
                newPassword: null,
                confirmPassword: null,
            },
            allplaces: null,
            verifyUrl: null,
            points: [],
            appliedplaces: null,
            verifyModalOpen: false,
            completedplaces: null,
            bankDetailsLoaded: false,
            isLoading: false,
            errorMessage: "",
            apiErrorMessage: "",
            formErrors: ""
        },
        reducers: {
            changeUserEmail: (state, email) => {
                state.user.email = email.payload
            },
            changeverifyUrl: (state, verifyUrl) => {
                state.verifyUrl = verifyUrl.payload
            },
            changepassword: (state, password) => {
                state.password.newPassword = password.payload
            },
            changeApiErrorMessage: (state, error) => {
                state.apiErrorMessage = error.payload
            },
            changeconfirmpassword: (state, password) => {
                state.password.confirmPassword = password.payload
            },
            changeoldpassword: (state, password) => {
                state.password.oldPassword = password.payload
            },
            changeverifyModalOpen: (state) => {
                state.verifyModalOpen = !state.verifyModalOpen
            },
            setProfileData: (state, profile) => {

                state.profile = profile.payload
                if (profile.payload.bank_detail != null && profile.payload.bank_detail.identification_number.length > 5) {
                    state.bank_details = profile.payload.bank_detail;
                    state.bank_details.identification_number1 = profile.payload.bank_detail.identification_number.toString().substring(0, 6);
                    state.bank_details.identification_number2 = profile.payload.bank_detail.identification_number.toString().substring(6, 7);
                    // state.bank_details = [...profile.payload.bank_detail, { identification_number1: profile.payload.bank_detail.identification_number.substring(0, 5) }]
                    // state.bank_details = [...profile.payload.bank_detail, { identification_number2: profile.payload.bank_detail.identification_number.substring(5, 6) }]
                    // state.bankDetailsLoaded = true

                } else {
                    state.bank_details = profile.payload.bank_detail ?? {
                        bank_name: null,
                        account_number: null,
                        account_name: null,
                        identification_number: null,
                        identification_number1: null,
                        identification_number2: null
                    }
                    state.bankDetailsLoaded = true

                }
                state.isLoading = false

            },
            setAllPlaces: (state, allplaces) => {
                state.allplaces = allplaces.payload.data.data
                state.isLoading = false

            },
            setPoints: (state, points) => {
                state.points = points.payload.data.data
                state.isLoading = false

            },
            setAppliedPlaces: (state, appliedplaces) => {
                state.appliedplaces = appliedplaces.payload.data.data
                state.isLoading = false

            },
            setCompletedPlaces: (state, completedplaces) => {
                state.completedplaces = completedplaces.payload.data.data
                state.isLoading = false

            },
            changeUserPassword: (state, password) => {
                state.user.password = password.payload
            },
            setFormErrors: (state, message) => {

                state.formErrors = message.payload
                state.isLoading = false
            },
            setAddFormErrors: (state, message) => {

                state.formErrors = message.payload
                state.isLoading = false
            },
            setEmployeeList: (state, employee) => {
                state.isLoading = false
                state.employeeList = employee.payload.data
            },
            setLoadingHidden: (state) => {
                state.isLoading = false
            },
            setLoadingScreen: (state) => {
                state.isLoading = true
            },

            changeBankName: (state, bank) => {
                state.bank_details.bank_name = bank.payload
            },
            changeAccountNumber: (state, acc) => {
                state.bank_details.account_number = acc.payload
            },
            changeAccountName: (state, acc) => {
                state.bank_details.account_name = acc.payload
            },
            changeIdentificationNo1: (state, identification_number1) => {
                state.bank_details.identification_number1 = identification_number1.payload
                state.bank_details.identification_number = identification_number1.payload + state.bank_details.identification_number2

            },
            changeIdentificationNo2: (state, identification_number2) => {
                state.bank_details.identification_number2 = identification_number2.payload
                state.bank_details.identification_number = state.bank_details.identification_number1 + identification_number2.payload
            },
        },
    })


export const home = createAsyncThunk(
    "auth/create_employee",
    async ({ email, password }, thunkAPI) => {

        try {

            var data = await homeServices.home(email, password)

            //thunkAPI.dispatch(setCompanyListData(data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const changePasswordNew = createAsyncThunk(
    "auth/create_employee",
    async ({ current_password, new_password, confirm_password }, thunkAPI) => {

        try {


            if (homeServices.validate_password(new_password, confirm_password)) {
                var data = await homeServices.changePassword({
                    current_password, new_password
                })

                if (data.error != null && data.error) {

                    var message = "확인 오류"


                    Object.entries(data.data).forEach(([key, val]) => {
                        message += ("\n" + val)
                    })

                    thunkAPI.dispatch(setAddFormErrors(message))
                    thunkAPI.abort()

                } else {
                    thunkAPI.fulfillWithValue()

                }
            } else {
                throw ({

                    response: {
                        data: {
                            message: "확인 오류"
                        }
                    }
                })
            }

            //thunkAPI.dispatch(setCompanyListData(data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const loadpoints = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await homeServices.getPoints()

            thunkAPI.dispatch(setPoints(data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (error.response.status == 401) {
                goToLogin();
            }

            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const loadallplaces = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await homeServices.allplaces()

            thunkAPI.dispatch(setAllPlaces(data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)



export const verifyPlace = createAsyncThunk(
    "auth/create_employee",
    async ({ place, url }, thunkAPI) => {

        try {

            var data = await homeServices.verifyPlace(place, url)

            if (data.error) {

                var message = "확인할 수 없습니다. 다시 시도해 주세요."


                Object.entries(data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })



                thunkAPI.dispatch(setAddFormErrors(message))
                thunkAPI.abort()

            } else {
                thunkAPI.fulfillWithValue()

            }
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }

            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const changeBankDetails = createAsyncThunk(
    "auth/create_employee",
    async ({ postData }, thunkAPI) => {

        try {
            if (homeServices.validate_bank(postData.bank_name, postData.account_number, postData.account_name, postData.identification1, postData.identification2)) {
                var data = await homeServices.changeBankDetails(postData)

                if (data.error) {

                    var message = "확인할 수 없습니다. 다시 시도해 주세요."


                    Object.entries(data.data).forEach(([key, val]) => {
                        message += ("\n" + val)
                    })



                    thunkAPI.dispatch(setAddFormErrors(message))
                    thunkAPI.abort()

                } else {
                    thunkAPI.fulfillWithValue()

                }
            } else {
                throw ({

                    response: {
                        data: {
                            message: "확인 오류"
                        }
                    }
                })
            }

            // return { Company: data }
        } catch (error) {

            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const applyplace = createAsyncThunk(
    "auth/create_employee",
    async ({ place }, thunkAPI) => {

        try {

            var data = await homeServices.applyPlace(place)

            if (data.error != null && data.error) {
                var errorMessage = data.message;
                if (errorMessage == "Blogger limit exceeded!") {
                    errorMessage = "모집이 완료된 플레이스입니다";
                }

                thunkAPI.dispatch(changeApiErrorMessage(errorMessage))
                thunkAPI.abort();
            } else {
                thunkAPI.fulfillWithValue()
            }
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }

            var errorMessage = message;
            if (errorMessage == "Blogger limit exceeded!") {
                errorMessage = "모집이 완료된 플레이스입니다";
            }

            thunkAPI.dispatch(changeApiErrorMessage(errorMessage))

            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)



export const loadappliedplaces = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await homeServices.appliedplaces()

            thunkAPI.dispatch(setAppliedPlaces(data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }

            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


export const getprofile = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await homeServices.getProfile()

            thunkAPI.dispatch(setProfileData(data.data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()


            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)

export const loadcompletedplaces = createAsyncThunk(
    "auth/create_employee",
    async ({ }, thunkAPI) => {

        try {

            var data = await homeServices.completedplaces()

            thunkAPI.dispatch(setCompletedPlaces(data))
            thunkAPI.fulfillWithValue()
            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }

            thunkAPI.dispatch(setAddFormErrors(message))

            if (error.response.status == 401) {
                goToLogin();
            }
            return thunkAPI.rejectWithValue()
        }
    }
)


// Action creators are generated for each case reducer function
export const { changeAccountName, changeApiErrorMessage, changeconfirmpassword, changeoldpassword, changepassword, changeverifyUrl, changeverifyModalOpen, setPoints, changeAccountNumber, changeBankName, changeIdentificationNo1, changeIdentificationNo2, changeUserEmail, setProfileData, setAllPlaces, setAppliedPlaces, setCompletedPlaces, changeUserPassword, setAddFormErrors, setFormErrors, setLoadingHidden, setLoadingScreen } = homeSlice.actions

var homeReducer = homeSlice.reducer

export default homeReducer