import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { changeverifyUrl, setFormErrors, setLoadingHidden, setLoadingScreen, verifyPlace } from "../state/home_state";
import { Title } from "@mui/icons-material";
import { Box, Button, FormControl, InputAdornment, Modal, TextField, Typography } from "@mui/material";

import ErrorIndicator from "../../common/views/components/ErrorIndicator";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "40vw",
    minHeight: "40vh",
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}

export function VerifyModal({ open, handleClose, placeId, placeName }) {

    const verifyUrl = useSelector((state) => state.home.verifyUrl)
    const errorMessage = useSelector((state) => state.home.formErrors)

    const dispatch = useDispatch()
    const navigator = useNavigate()

    function handleSave() {
        if (verifyUrl != null && (verifyUrl.includes("https://blog.naver.com/") || verifyUrl.includes("https://m.blog.naver.com/"))) {
            dispatch(setLoadingScreen())
            dispatch(verifyPlace({ place: placeId, url: verifyUrl })).unwrap().then(() => {
                dispatch(setLoadingHidden())
                dispatch(setFormErrors(""))
                toast.success("확인 성공")
                navigator("/completed")

            })
        } else {
            toast.error("잘못된 URL.")
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box id="modal-modal-description" sx={{ mt: 2 }}>
                    <FormControl sx={{ textAlign: "left", m: 1, width: '100%' }}>
                        <ErrorIndicator
                            message={errorMessage}
                        />
                    </FormControl>

                    <Typography variant="h5" style={{ textAlign: 'center' }}>
                        {placeName}
                    </Typography>

                    <ol>
                        <li>메인키워드가 본문에 3번이상 들어갔나요?</li>
                        <li>서브 키워드가 본문에 1번씩 모두 들어갔나요?</li>
                        <li>해시태그를 정확하게 모두 삽입하셨나요?</li>
                        <li>플레이스를 정확하게 삽입하셨나요?</li>
                        <li>250글자이상 작성하셨나요?</li>
                        <li>이미지는 5개이상 첨부하셨나요?</li>
                        <li>제목을 20글자 이상 작성하셨나요?</li>
                        <li>신청 후 24시간내에 블로그를 작성하셨나요?</li>
                    </ol>

                    <Typography style={{ textAlign: 'center' }}>
                        모두 제대로 작성하셨다면 PC버전의 URL을 입력하시고 검수버튼을 눌러주세요
                    </Typography>

                    <br />

                    <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>
                        블로그 주소 URL 예: https://blog.naver.com/blogid/123456789
                    </Typography>

                    <FormControl sx={{ m: 1, width: '100%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Title />
                                    </InputAdornment>
                                ),
                            }}
                            id="url"
                            label="URL"
                            type="text"
                            defaultValue=""
                            value={verifyUrl ?? ''}
                            onChange={(e) => {
                                dispatch(changeverifyUrl(e.target.value))
                            }}
                            placeholder="Enter URL"
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '100%', alignContent: 'center', alignItems: 'center', flexDirection: "row", justifyContent: "center" }}>
                        <Button variant="contained" sx={{ width: '100px', float: 'right' }} onClick={() => {
                            handleClose()
                        }} disableElevation>
                            닫기
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="contained" sx={{ width: '100px', float: 'right' }} onClick={() => {
                            handleSave()
                        }} disableElevation>
                            등록하기
                        </Button>
                    </FormControl>
                </Box>
            </Box>
        </Modal>
    );
}