import { AddAlert, AttachMoney, ChatRounded, DoneAll, Place, QuestionAnswer, VerifiedUser, Logout } from "@mui/icons-material";
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Drawer } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Sidebar({drawerOpen, handleDrawerToggle}) {

    var navigate = useNavigate();
    var drawerWidth = 300;

    return (
        <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            <Drawer
                variant="temporary"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <List subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Menu
                        </ListSubheader>
                    }>
                        <ListItem onClick={() => {
                            navigate("/home")
                        }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Place />
                                </ListItemIcon>
                                <ListItemText primary="모든 플레이스" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={() => {
                            navigate("/applied")
                        }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AddAlert />
                                </ListItemIcon>
                                <ListItemText primary="내가 지원한 플레이스" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={() => {
                            navigate("/completed")
                        }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <DoneAll />
                                </ListItemIcon>
                                <ListItemText primary="내가 완료한 플레이스" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={() => {
                            navigate("/my_info")
                        }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <VerifiedUser />
                                </ListItemIcon>
                                <ListItemText primary="내 정보" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={() => {
                            navigate("/my_points")
                        }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AttachMoney />
                                </ListItemIcon>
                                <ListItemText primary="내 포인트" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={() => {
                            navigate("/faq")
                        }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <QuestionAnswer />
                                </ListItemIcon>
                                <ListItemText primary="자주 묻는 질문" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem 
                            onClick={() => {
                                localStorage.clear();
                                window.top.location = "/";
                            }}
                            sx={{ position: 'fixed', bottom: 0 }}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary="로그 아웃" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>

            <Drawer
                variant="permanent"
                anchor="left"
                elevation={0}
                ModalProps={{
                    keepMounted: true,
                }}
                open
                width={drawerWidth}
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'block' },
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <List
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Menu
                        </ListSubheader>
                    }
                >
                    <ListItem onClick={() => {
                        navigate("/home")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Place />
                            </ListItemIcon>
                            <ListItemText primary="모든 플레이스" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        navigate("/applied")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <AddAlert />
                            </ListItemIcon>
                            <ListItemText primary="내가 지원한 플레이스" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        navigate("/completed")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <DoneAll />
                            </ListItemIcon>
                            <ListItemText primary="내가 완료한 플레이스" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        navigate("/my_info")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <VerifiedUser />
                            </ListItemIcon>
                            <ListItemText primary="내 정보" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        navigate("/my_points")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <AttachMoney />
                            </ListItemIcon>
                            <ListItemText primary="내 포인트" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        navigate("/faq")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <QuestionAnswer />
                            </ListItemIcon>
                            <ListItemText primary="자주 묻는 질문" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem 
                        onClick={() => {
                            localStorage.clear();
                            window.top.location = "/";
                        }}
                        sx={{ position: 'fixed', bottom: 0 }}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <ListItemText primary="로그 아웃" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </Box>
    )
}