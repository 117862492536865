import { AccountCircle, AddAlert, Approval, DoneAll, Place, QuestionAnswer, Verified, VerifiedUser } from "@mui/icons-material";
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material"
import { useNavigate } from "react-router-dom";


export default function SidebarAdmin() {

    var navigate = useNavigate();

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
                <List subheader={
                    <ListSubheader sx={{ textAlign: "left" }} component="div" id="nested-list-subheader">
                        Menu
                    </ListSubheader>
                }>

                    <ListItem onClick={() => {
                        navigate("/admin_home")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <AccountCircle />
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => {
                        navigate("/admin_requests")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <DoneAll />
                            </ListItemIcon>
                            <ListItemText primary="Requests" />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem onClick={() => {
                        navigate("/verified_blogs")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Verified />
                            </ListItemIcon>
                            <ListItemText primary="Verified Blogs" />
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem onClick={() => {
                        navigate("/bank_approvals")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Approval />
                            </ListItemIcon>
                            <ListItemText primary="Bank Approvals" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem onClick={() => {
                        navigate("/admin_faq")
                    }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <QuestionAnswer />
                            </ListItemIcon>
                            <ListItemText primary="FAQ" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </nav>


        </Box>
    )
}