import { toast } from "react-toastify";
import loginRepo from "../repository/login_repo";

async function login(email, password) {

    var response = await loginRepo.login(email, password)

    return response;
}

function validate_login(email, password) {

    if (email == null || email.trim() == "") {
        toast.error("Email을 입력해주세요.")
        return false
    }
    if (!email.trim().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        toast.error("유효하지 않은 이메일 주소 형식입니다.")
        return false
    }

    if (password == null || password.trim() == "") {
        toast.error("비밀번호를 입력해주세요.")
        return false
    }

    if (password.trim().length < 5) {
        toast.error("비밀번호는 5자 이상이어야 합니다.")
        return false
    }

    return true
}

const loginServices = {
    login,
    validate_login
}

export default loginServices

