import { DateRange, FolderZip, Numbers, People, Place, Search, Title } from "@mui/icons-material";
import { Box, Button, Container, FormControl, Grid, InputAdornment, InputLabel, Modal, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import { useDispatch, useSelector } from "react-redux";
import { changeFaqContent, changeFaqModalOpen, changeFaqTitle, changeOrder, changeRequestBlogLimit, changeRequestDueDate, changeRequestHashTag, changeRequestMainKeyword, changeRequestName, changeRequestPlaceURL, changeRequestSubKeyword, changeRequestUserList, changeSearchUrl, getfaq, getrequests, removeMainFileData, removeSubFileData, saveRequests, savefaq, setFormErrors, setLoadingHidden, setLoadingScreen, updateRequests, updatefaq, uploadPhotoMain, uploadPhotoSub } from "../state/admin_home_state";
import ErrorIndicator from "../../common/views/components/ErrorIndicator";
import { toast } from "react-toastify";
import { IMG_URL } from "../../common/repository/api_routes";
import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60vw",
    minHeight: "60vh",
    overflow: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}


export function RequestModal({ open, handleClose }) {

    const request = useSelector((state) => state.adminhome.request)
    const isClone = useSelector((state) => state.adminhome.isClone)
    const errorMessage = useSelector((state) => state.adminhome.formErrors)
    const users = useSelector((state) => state.adminhome.userList)

    var dispatch = useDispatch()


    // Handle changes to the selected users in the multi-select
    const handleUserSelect = (event) => {
        const selectedUsers = event.target.value;
        dispatch(changeRequestUserList(selectedUsers));  // Action to update the user list in state
    };

    function handleSave() {
        dispatch(changeRequestDueDate('2100-01-01'))
        dispatch(setLoadingScreen())
        if (request.id == null || isClone == true) {
            dispatch(saveRequests(request)).unwrap().then(() => {
                dispatch(setLoadingHidden())
                dispatch(setFormErrors(""))
                toast.success("Saved Request Successfully")
                dispatch(changeFaqModalOpen())
                dispatch(setLoadingScreen())
                dispatch(getrequests({})).unwrap().then(() => {
                    dispatch(setLoadingHidden())
                })

            })
        } else {

            dispatch(updateRequests({ id: request.id, formData: request })).unwrap().then(() => {

                dispatch(setFormErrors(""))
                toast.success("Updated Request Successfully")
                dispatch(changeFaqModalOpen())
                dispatch(setLoadingScreen())
                dispatch(getrequests({})).unwrap().then(() => {
                    dispatch(setLoadingHidden())
                })

            })
        }
    }

    function uploadMainPhoto(e) {
        dispatch(setLoadingScreen());
        dispatch(uploadPhotoMain(e.target.files[0])).unwrap().then(() => {

            dispatch(setLoadingHidden());

        })
    }
    function uploadSubPhoto(e) {
        dispatch(setLoadingScreen());
        dispatch(uploadPhotoSub(e.target.files)).unwrap().then(() => {

            dispatch(setLoadingHidden());

        })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Request
                </Typography>
                <Container id="modal-modal-description" sx={{ mt: 2, maxHeight: "80vh" }}>
                    <FormControl sx={{ textAlign: "left", m: 1, width: '80%' }}>
                        <ErrorIndicator
                            message={errorMessage}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Title />
                                    </InputAdornment>
                                ),
                            }}
                            id="name"
                            label="Name"
                            type="text"
                            defaultValue=""
                            value={request.name ?? ''}
                            onChange={(e) => {
                                dispatch(changeRequestName(e.target.value))
                            }}
                            placeholder="Enter Name"

                        />
                    </FormControl>

                    {/* Multi-select for users */}
                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <InputLabel id="users-select-label">Restricted Users</InputLabel>
                        <Select
                            labelId="users-select-label"
                            id="users-select"
                            multiple
                            value={request.restricted_users ?? []}  // Bind to Redux state

                            renderValue={(selected) => (users != null ? request.restricted_users?.map(
                                (userId) => users.find((user) => user.id === userId)?.email
                            ) || [] : []).join(', ')}  // Display names instead of IDs

                            onChange={handleUserSelect}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4.5 + 8,
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {users != null ? users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                    <Checkbox checked={request.restricted_users?.includes(user.id)} />
                                    <ListItemText primary={user.email} />
                                </MenuItem>
                            )) : []}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Title />
                                    </InputAdornment>
                                ),
                            }}
                            id="main_keyword"
                            label="Main Keyword"
                            type="text"
                            defaultValue=""
                            value={request.main_keyword ?? ''}
                            onChange={(e) => {
                                dispatch(changeRequestMainKeyword(e.target.value))
                            }}
                            placeholder="Enter Main Keyword"

                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Title />
                                    </InputAdornment>
                                ),
                            }}
                            id="sub_keywords"
                            label="Sub Keywords"
                            type="text"
                            defaultValue=""
                            value={request.sub_keywords ?? ''}
                            onChange={(e) => {
                                dispatch(changeRequestSubKeyword(e.target.value))
                            }}
                            placeholder="Enter Sub Keywords"

                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Place />
                                    </InputAdornment>
                                ),
                            }}
                            id="place_url"
                            label="Place URL"
                            type="text"
                            defaultValue=""
                            value={request.place_url ?? ''}
                            onChange={(e) => {
                                dispatch(changeRequestPlaceURL(e.target.value))
                            }}
                            placeholder="Enter Place URL"

                        />
                    </FormControl>


                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Numbers />
                                    </InputAdornment>
                                ),
                            }}
                            id="hashtags"
                            label="Hash Tags"
                            type="text"
                            defaultValue=""
                            value={request.hashtags ?? ''}
                            onChange={(e) => {
                                dispatch(changeRequestHashTag(e.target.value))
                            }}
                            placeholder="Enter Hash Tags"

                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <People />
                                    </InputAdornment>
                                ),
                            }}
                            id="blogger_limit"
                            label="Blogger Limit"
                            type="number"
                            defaultValue="5"
                            value={request.blogger_limit ?? ''}
                            onChange={(e) => {
                                dispatch(changeRequestBlogLimit(e.target.value))
                            }}
                            placeholder="Enter Blogger Limit"

                        />
                    </FormControl>
                    {/* <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <DateRange />
                                    </InputAdornment>
                                ),
                            }}
                            id="due_date"
                            label="Due Date"
                            type="date"

                            value={request.due_date ?? ''}
                            onChange={(e) => {
                                dispatch(changeRequestDueDate(e.target.value))
                            }}
                            placeholder="Enter Due Date"

                        />
                    </FormControl> */}

                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            id="search_url"
                            label="Search URL"
                            type="text"

                            value={request.searched_url ?? ''}
                            onChange={(e) => {
                                dispatch(changeSearchUrl(e.target.value))
                            }}
                            placeholder="Search URL"

                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '40%' }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <People />
                                    </InputAdornment>
                                ),
                            }}
                            id="order_limit"
                            label="Order"
                            type="number"
                            defaultValue="10"
                            value={request.order ?? ''}
                            onChange={(e) => {
                                dispatch(changeOrder(e.target.value))
                            }}
                            placeholder="Enter Order"

                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, width: '100%' }}>
                        <Button sx={{ m: 0, width: '80%' }} variant="contained" component="label">
                            Main Image
                            <input hidden accept="image/*" type="file" onChange={(e) => uploadMainPhoto(e)} />
                        </Button>

                        {request.main_image != null && request.main_image != "" ?
                            <Stack>
                                <a onClick={() => dispatch(removeMainFileData())}>&times;</a>

                                <img width={"50%"} src={IMG_URL + "/" + request.main_image} />

                            </Stack> :
                            <div></div>}

                    </FormControl>

                    <FormControl sx={{ m: 1, width: '100%' }}>
                        <Button sx={{ m: 0, width: '80%' }} variant="contained" component="label">
                            Set Image Zip
                            <input hidden accept=".zip" multiple type="file" onChange={(e) => uploadSubPhoto(e)} />
                        </Button>

                        <Grid sx={{ marginTop: "20px" }} container spacing={2}>
                            {request.images != null ?
                                request.images.map((file, key) => {

                                    return (
                                        <Grid key={key} xs={3}>
                                            <Stack>
                                                <a onClick={() => dispatch(removeSubFileData(file))}>&times;</a>
                                                {file.endsWith('.zip') ? (
                                                    // Render the zip icon for zip files
                                                    <> <FolderZip style={{ fontSize: "50px" }} />
                                                        <span>Zip File</span>
                                                    </>
                                                ) : (
                                                    // Render the image for non-zip files
                                                    <img width="80%" src={`${IMG_URL}/${request.id != null ? file : file}`} alt="file" />
                                                )}
                                            </Stack>
                                        </Grid>
                                    );

                                })
                                :
                                <div></div>}
                        </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%', alignItems: "end" }}>

                        <Button variant="contained" sx={{ width: '100px', float: 'right' }} onClick={() => {
                            handleSave()
                        }} disableElevation>
                            {request.id == null || isClone ? 'Save' : 'Update'}
                        </Button>
                    </FormControl>


                </Container>
            </Box>
        </Modal>
    )
}