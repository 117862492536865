import { Button, Card, Container } from "@mui/material";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import SidebarAdmin from "../../common/views/components/SideBarAdmin";
import { useDispatch, useSelector } from "react-redux";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { DataGrid, GridColDef, GridValueGetterParams, koKR } from '@mui/x-data-grid';
import { changeFaqModalOpen, deleteFaq, deleteRequests, getblogs, getfaq, getrequests, getusers, setFormErrors, setLoadingHidden, setLoadingScreen, setUpdateFaq, setUpdateRequest } from "../state/admin_home_state";
import { useEffect } from "react";
import { FaqModal } from "./FaqModal";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import { Add, Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { RequestModal } from "./RequestsModal";


function SubmissionsView() {

    const requests = useSelector((state) => state.adminhome.userBlogs)
    const faqModalOpen = useSelector((state) => state.adminhome.faqModalOpen)
    const isLoading = useSelector((state) => state.adminhome.formProcessing)

    var dispatch = useDispatch()

    function handleDelete(id) {

        // dispatch(setLoadingScreen())
        // dispatch(deleteRequests(id)).unwrap().then(() => {
        //     dispatch(setLoadingHidden())
        //     dispatch(setFormErrors(""))
        //     toast.success("Deleted Request Successfully")
        //     dispatch(getrequests({}))

        // })
    }

    function initState() {

        dispatch(getblogs({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {

            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);


    const columns = [
        { field: 'id', headerName: 'ID', width: 100, },
        { field: 'name', headerName: 'Place Name', width: 100, },
        { field: 'main_keyword', headerName: 'Main Keyword', width: 100, },
        { field: 'sub_keywords', headerName: 'Sub Keywords', width: 100, },
        { field: 'place_url', headerName: 'Place URL', width: 300, },
        { field: 'email', headerName: 'Email', width: 250, },
        { field: 'url', headerName: 'Blog URL', width: 350, },
        { field: 'time', headerName: 'Verified Time', width: 300, }
    ];

    var rows = [

    ];

    if (requests != null) {
        rows = (requests).map((request, index) => {
            return {
                id: index + 1,
                name: request.blog_request.name,
                place_url: request.blog_request.place_url,
                main_image: request.main_image,
                url: request.url,
                time: getFormatedDate(request.created_at),
                email: request.user.email,
                searched_url: request.searched_url,

                main_keyword: request.blog_request.main_keyword,
                sub_keywords: request.blog_request.sub_keywords,
                request: request
            }
        })
    }

    return (

        <div>
            <LoadingIndicator
                show={isLoading}
            />
            <AppBarComponent
                title={APP_NAME}
            />

            <Container sx={{ minWidth: "300px", width: "100vw", margin: "auto", alignContent: "start", justifyContent: "start", justifyItems: "start", alignItems: "start", flexDirection: "row", display: "flex", padding: "0px !important", paddingTop: "0px", margin: "0px !important", maxWidth: "100vw !important" }}>
                <Card variant="outlined" sx={{ minWidth: "200px", width: "300px", margin: "0px", minHeight: "100vh" }}>
                    <SidebarAdmin />
                </Card>
                <Container sx={{ minWidth: "300px", width: "calc(100vw-300px)", margin: "0px", paddingTop: "10px",maxWidth:'unset !important'  }}>

                    <div style={{ height: '70vh', width: '100%',maxWidth:'unset !important'  }}>
                        <DataGrid
                            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}

                            sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    textAlign: "center"
                                },
                                "& .MuiDataGrid-cellContent": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    textAlign: "left"
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    // Forced to use important since overriding inline styles
                                    height: "unset !important"
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    // Forced to use important since overriding inline styles
                                    maxHeight: "168px !important"
                                }
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={5}

                            rowsPerPageOptions={[5]}
                            rowSelection={false}

                        />
                    </div>
                </Container>
            </Container>

        </div>

    );
}

function getFormatedDate(dateNow) {
    const date = new Date(dateNow);

    // Extract the individual components of the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the components into the desired format
    const formattedDate = `${year}.${month}.${day} ${hours}.${minutes}.${seconds}`;


    return formattedDate;
}


export default SubmissionsView;