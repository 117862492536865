import MyPointsAdminView from "./MyPointsAdminView";

function MyPointsAdmin() {

    return (
        <div className="App">
            <MyPointsAdminView />
        </div>
    );
}

export default MyPointsAdmin;