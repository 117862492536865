import { AccountCircle, Lock, Password } from "@mui/icons-material";
import { Avatar, Button, Card, CardContent, Container, Divider, FormControl, InputAdornment, TextField, Typography, Grid, Box } from "@mui/material"
import { Link, useNavigate } from "react-router-dom";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { useDispatch, useSelector } from "react-redux";
import { changeUserEmail, changeUserPassword, loginUser, setLoadingHidden, setLoadingScreen } from "../state/login_state";
import ErrorIndicator from "../../common/views/components/ErrorIndicator";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";


function LoginView() {

    const user = useSelector((state) => state.login.user)
    const errorMessage = useSelector((state) => state.login.errorMessage)
    const isLoading = useSelector((state) => state.login.isLoading)

    var dispatch = useDispatch()
    var navigator = useNavigate();

    function handleLogin() {
        dispatch(setLoadingScreen())
        dispatch(loginUser({ email: user.email, password: user.password })).unwrap().then(() => {
            dispatch(setLoadingHidden())
            navigator("/home")
        })
    }

    return (

        <Box>
            <LoadingIndicator
                show={isLoading}
            />
            <AppBarComponent
                title={APP_NAME}
                drawerWidth={0}
            />
            <br /><br />

            <Card sx={{ minWidth: "300px", width: { xs: 'auto', sm: 'auto', md: '40vw' }, marginTop: 10,  marginLeft: { xs: 2, sm: 2, md: 'auto' }, marginRight: { xs: 2, sm: 2, md: 'auto' } }}>
                <CardContent>

                    <FormControl sx={{ textAlign: "left", width: '100%', marginBottom: 2, }}>
                        <ErrorIndicator
                            message={errorMessage}
                        />
                    </FormControl>
                    <FormControl sx={{ textAlign: "left", width: '100%', marginBottom: 3, }}>
                        <Typography variant="h6">
                            로그인
                        </Typography>
                    </FormControl>
                    <FormControl sx={{ width: '100%', marginBottom: 2, }}>
                        <TextField
                            required
                            id="email"
                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            label="Email"
                            defaultValue=""
                            value={user.email ?? ''}
                            onChange={(e) => {
                                dispatch(changeUserEmail(e.target.value))
                            }}
                            placeholder="Email을 입력해주세요"
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%', marginBottom: 2, }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                            id="password"
                            label="Password"
                            type="password"
                            defaultValue=""
                            value={user.password ?? ''}
                            onChange={(e) => {
                                dispatch(changeUserPassword(e.target.value))
                            }}
                            placeholder="비밀번호를 입력해주세요."

                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '80%', justifyContent: 'space-between', flexDirection: "row" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Link to="https://open.kakao.com/o/s5naSDdf" rel="noreferrer" target="_blank">
                                    <Typography >
                                        <b>비밀번호찾기는 관리자 카카오톡으로 문의해주세요.</b>
                                    </Typography>
                                </Link>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Button variant="contained" sx={{ width: '100px' }} onClick={() => {
                                    handleLogin()
                                }} disableElevation>
                                    로그인
                                </Button>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%', textAlign: "center" }}>
                        <center>
                            <Divider />
                        </center>
                    </FormControl>
                    <br />
                    <FormControl sx={{ m: 1, width: '80%', flexDirection: "row", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                        <Avatar sx={{ width: "25px", height: "25px" }} />
                        &nbsp;&nbsp;&nbsp;
                        <Link to="register">
                            <Typography>
                                <b>회원가입</b>
                            </Typography>
                        </Link>
                    </FormControl>
                    <br />
                    <FormControl sx={{ m: 1, width: '80%', flexDirection: "row", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                        <Avatar sx={{ width: "25px", height: "25px" }} />
                        &nbsp;&nbsp;&nbsp;
                        <Link to="admin_login">
                            <Typography>
                                <b>Admin</b>
                            </Typography>
                        </Link>
                    </FormControl>

                </CardContent>
            </Card>
        </Box>
    );
}

export default LoginView;