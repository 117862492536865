import RequestViewAdmin from "./RequestsView";

function RequestHome() {

    return (
        <div className="App">
            <RequestViewAdmin />
        </div>
    );
}

export default RequestHome;