import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import registerServices from '../services/register_services'


export const registerStatus = {
    INTIAL: 'INTIAL',
}

export const registerSlice = createSlice(
    {
        name: 'register',
        initialState: {
            user: {
                email: null,
                password: null,
                phone: null,
                confirm_password: null,
                blog_id: 'https://blog.naver.com/'
            },
            bank: {
                bank_name: null,
                account_number: null,
                account_name: null,
                identification_number: null,
                identification_number1: null,
                identification_number2: null
            },

            isLoading: false,
            errorMessage: "",
            singleErrorMessage: "",
            formNo: 1
        },
        reducers: {
            changeFormNo: (state, formNo) => {
                state.formNo = formNo.payload
            },
            changeUserEmail: (state, email) => {
                state.user.email = email.payload
            },
            changeBankName: (state, bank) => {
                state.bank.bank_name = bank.payload
            },
            changeAccountNumber: (state, acc) => {
                state.bank.account_number = acc.payload
            },
            changeAccountName: (state, acc) => {
                state.bank.account_name = acc.payload
            },
            changeIdentificationNo1: (state, identification_number1) => {
                state.bank.identification_number1 = identification_number1.payload
            },
            changeIdentificationNo2: (state, identification_number2) => {
                state.bank.identification_number2 = identification_number2.payload
            },
            setUserData: (state, user) => {
                localStorage.setItem("token", user.payload.data.accessToken)
                localStorage.setItem("user", JSON.stringify([user.payload.data.user]))
                state.formNo = 2
            },
            changeUserPassword: (state, password) => {
                state.user.password = password.payload
            },
            changeUserConfirmPassword: (state, confirm_password) => {
                state.user.confirm_password = confirm_password.payload
            },
            changeUserPhone: (state, phone) => {
                state.user.phone = phone.payload
            },
            changeUserBlogId: (state, blog_id) => {
                state.user.blog_id = blog_id.payload
            },
            setFormErrors: (state, message) => {
                state.errorMessage = message.payload
                state.isLoading = false
            },
            setFormSingleErrors: (state, message) => {

                state.singleErrorMessage = message.payload
                state.isLoading = false
            },
            setAddFormErrors: (state, message) => {

                state.errorMessage = message.payload
                state.isLoading = false
            },
            setEmployeeList: (state, employee) => {
                state.isLoading = false
                state.employeeList = employee.payload.data
            },
            setLoadingHidden: (state) => {
                state.isLoading = false
            },
            setLoadingScreen: (state) => {
                state.isLoading = true
            },
        },
    })


export const registerUser = createAsyncThunk(
    "auth/create_employee",
    async ({ email, password, confirm_password, phone, blog_id }, thunkAPI) => {

        try {
            if (registerServices.validate_register(email, password, confirm_password, phone, blog_id)) {
                var response = await registerServices.register(email, password, phone, blog_id)
                thunkAPI.dispatch(setUserData(response))
                thunkAPI.fulfillWithValue()
            } else {
                throw ({

                    response: {
                        data: {
                            message: "확인 오류"
                        }
                    }
                })
            }

            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }

            thunkAPI.dispatch(setAddFormErrors(message))
            return thunkAPI.rejectWithValue()
        }
    }
)


export const bankAccountSave = createAsyncThunk(
    "auth/create_employee",
    async ({ bank_name, account_number, account_name, identification1, identification2 }, thunkAPI) => {

        try {
            if (registerServices.validate_bank(bank_name, account_number, account_name, identification1, identification2)) {
                var response = await registerServices.bankAccountSave(bank_name, account_number, account_name, identification1, identification2)
                thunkAPI.fulfillWithValue()
            } else {
                throw ({

                    response: {
                        data: {
                            message: "확인 오류"
                        }
                    }
                })
            }

            // return { Company: data }
        } catch (error) {
            var message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (message == "Validation Error") {
                Object.entries(error.response.data.data).forEach(([key, val]) => {
                    message += ("\n" + val)
                })
            }

            thunkAPI.dispatch(setAddFormErrors(message))
            return thunkAPI.rejectWithValue()
        }
    }
)

// Action creators are generated for each case reducer function
export const { changeUserEmail, changeAccountName, changeAccountNumber, changeBankName, changeIdentificationNo1, changeIdentificationNo2, changeUserBlogId, setUserData, changeUserConfirmPassword, changeUserPhone, setFormSingleErrors, changeFormNo, changeUserPassword, setAddFormErrors, setFormErrors, setLoadingHidden, setLoadingScreen } = registerSlice.actions

var registerReducer = registerSlice.reducer

export default registerReducer