import configname from '../../../config_env.json'

const APP_NAME = configname.appName
// const API_URL = config.app_backend
// const WEB_URL = config.web_path
var API_TOKEN = localStorage.getItem("token")

export function changeApiKey() {
    API_TOKEN = localStorage.getItem("token")
}


export { APP_NAME }
