import CompletedView from "./CompletedView";

function Completed() {

    return (
        <div className="App">
            <CompletedView />
        </div>
    );
}

export default Completed;