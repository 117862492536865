import MyPointsView from "./MyPointsView";

function MyPoints() {

    return (
        <div className="App">
            <MyPointsView />
        </div>
    );
}

export default MyPoints;