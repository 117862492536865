import { Button, Card, Container, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/views/components/SideBar";
import { useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport, koKR } from "@mui/x-data-grid";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import SidebarAdmin from "../../common/views/components/SideBarAdmin";
import { addPoints, changePointAmount, changePointReason, changePointType, getusers, setFormErrors, setLoadingHidden, setLoadingScreen } from "../state/admin_home_state";
import { toast } from "react-toastify";
import ErrorIndicator from "../../common/views/components/ErrorIndicator";


function MyPointsAdminView() {
    const location = useLocation()
    const pointnew = useSelector((state) => state.adminhome.pointnew)
    const isLoading = useSelector((state) => state.adminhome.formProcessing)
    const errorMessage = useSelector((state) => state.adminhome.formErrors)
    const users = useSelector((state) => state.adminhome.userList)

    let user = location.state.user;

    var points = user.point_history

    if (users != null) {
        points = users.filter((e) => e.id == user.id)[0].point_history
    }

    var dispatch = useDispatch()

    function initState() {
        dispatch(getusers({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {

            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);



    const columns = [
        { field: 'status', headerName: '상태', flex: 0.5 },
        { field: 'date_time', headerName: '일시', flex: 1.5 },
        { field: 'point', headerName: '포인트', flex: 1.5 },
        { field: 'total', headerName: '총 포인트', flex: 1.5 },

    ];

    var rows = [

    ];
    var totalPoints = 0;
    if (points != null) {
        rows = (points).map((point, index) => {
            totalPoints += point.amount * (point.type == "debit" ? -1 : 1);
            return { id: index + 1, status: point.reason, date_time: getFormatedDate(point.created_at), point: point.amount * (point.type == "debit" ? -1 : 1), total: point.total_points }
        })
    }


    return (

        <div style={{ textAlign: 'left' }}>
            <LoadingIndicator
                show={isLoading}
            />
            <AppBarComponent
                title={APP_NAME}
                isAdmin={true}
            />
            <Container sx={{ minWidth: "300px", width: "100vw", margin: "auto", alignContent: "start", justifyContent: "start", justifyItems: "start", alignItems: "start", flexDirection: "row", display: "flex", padding: "0px !important", paddingTop: "0px", margin: "0px !important", maxWidth: "100vw !important" }}>
                <Card variant="outlined" sx={{ minWidth: "200px", width: "300px", margin: "0px", minHeight: "100vh" }}>
                    <SidebarAdmin />
                </Card>
                <Container sx={{ minWidth: "300px", width: "calc(100vw-300px)", margin: "0px", paddingTop: "10px", maxWidth: 'unset !important' }}>
                    <div style={{ height: '70vh', width: '100%', maxWidth: 'unset !important' }}>
                        <Typography variant="h5">
                            {user.email} {totalPoints}
                        </Typography>
                        <br />
                        <ErrorIndicator
                            message={errorMessage}
                        />
                        <br />
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <TextField
                                        width="100%"
                                        required
                                        id="amount"
                                        label="Amount"
                                        type="number"
                                        defaultValue=""
                                        value={pointnew.amount}
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/

                                            if (e.target.value.match(re) || e.target.value == '') {
                                                dispatch(changePointAmount(e.target.value))
                                            }
                                        }
                                        }
                                        placeholder=""

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <Select
                                        width="100%"
                                        required
                                        id="type"

                                        defaultValue=""
                                        value={pointnew.type}
                                        onChange={(e) => {
                                            dispatch(changePointType(e.target.value))

                                        }
                                        }
                                        placeholder=""

                                    >
                                        <MenuItem value={'debit'}>Debit</MenuItem>
                                        <MenuItem value={'credit'}>Credit</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <TextField
                                        width="100%"
                                        required
                                        id="reason"
                                        label="Reason"
                                        type="text"
                                        defaultValue=""
                                        value={pointnew.reason}
                                        onChange={(e) => {
                                            dispatch(changePointReason(e.target.value))

                                        }
                                        }
                                        placeholder=""

                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl sx={{ m: 1, width: '100%', justifyItems: "center", alignItems: "center" }}>
                                    <Button sx={{ width: "100%" }} variant="contained" color="success" onClick={() => {
                                        if (parseFloat(pointnew.amount) > 0 && pointnew.reason != null) {
                                            dispatch(setLoadingScreen())

                                            dispatch(addPoints({
                                                amount: parseFloat(pointnew.amount),
                                                type: pointnew.type,
                                                reason: pointnew.reason,
                                                id: user.id
                                            })).unwrap().then(() => {
                                                dispatch(setLoadingHidden())
                                                dispatch(setFormErrors(""))
                                                toast.success("Added Points Successfully")
                                                dispatch(getusers({}))

                                            })
                                        }
                                    }}>Add</Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />
                        <DataGrid
                            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                            slots={{ toolbar: CustomToolbar }}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            rowSelection={false}

                        />
                    </div>

                </Container>
            </Container>
        </div>

    );
}

function getFormatedDate(dateNow) {
    const date = new Date(dateNow);

    // Extract the individual components of the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the components into the desired format
    const formattedDate = `${year}.${month}.${day} ${hours}.${minutes}.${seconds}`;


    return formattedDate;
}

function CustomToolbar() {
    const location = useLocation()

    let user = location.state.user;

    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{ allColumns: true, fileName: "points_history_" + user.email, utf8WithBom: true }}
            />
        </GridToolbarContainer>
    );
}

export default MyPointsAdminView;