import { useSelector } from "react-redux";
import RegisterView from "./RegisterView";
import RegisterViewStep2 from "./RegisterViewStep2";

export default function Register() {

    const formNo = useSelector((state) => state.register.formNo)

    return (
        <div className="App">
            {formNo == null || formNo == 1 ? <RegisterView /> : <RegisterViewStep2 />}


        </div>
    )
}