import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './modules/login/state/login_state'
import registerReducer from './modules/register/state/register_state'
import faqReducer from './modules/faq/state/faq_state'
import homeReducer from './modules/home/state/home_state'
import adminloginReducer from './modules/adminlogin/state/admin_login_state'
import adminhomeReducer from './modules/adminuser/state/admin_home_state'

export default configureStore({
  reducer: {
    login: loginReducer,
    register: registerReducer,
    faq: faqReducer,
    home: homeReducer,
    adminlogin: adminloginReducer,
    adminhome:adminhomeReducer
  },
})