import { Button, Card, Container, Grid, Table, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../common/views/components/SideBar";
import { useEffect } from "react";
import { IMG_URL } from "../../common/repository/api_routes";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import SidebarAdmin from "../../common/views/components/SideBarAdmin";
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

function PlaceDetailAdminView() {

    const user = useSelector((state) => state.home.user)
    const allplaces = useSelector((state) => state.home.allplaces)
    const isLoading = useSelector((state) => state.home.isLoading)
    const apiErrorMessage = useSelector((state) => state.home.apiErrorMessage)

    var dispatch = useDispatch()
    var navigator = useNavigate()
    const location = useLocation();
    var exportString = ""
    function initState() {


    }

    function makeCSV() {

        // get userId
        let place = location.state.place;

        exportString = [["Name", "Main Keyword", "Sub Keywords", "Hash Tags", "Due Date", "Search URL", "Place URL", "Blog Limit", "Applied Count"]]
        exportString.push([place.name, place.main_keyword, place.sub_keywords.replaceAll(",", " "), place.hashtags.replaceAll(",", " "), place.due_date, place.searched_url, place.place_url, place.blogger_limit, place.applied_count])
        exportString.push([])
        exportString.push([])
        exportString.push(["Email", "Applied At"])

        if (place.bloggers != null) {
            place.bloggers.forEach((e) => {
                exportString.push([e.user.email, getFormatedDate(e.created_at)])
            })
        }

        exportString.push([])
        exportString.push([])
        exportString.push(["Email", "URL", "Verified At"])
        if (place.verified_submissions != null) {
            place.verified_submissions.forEach((e) => {
                exportString.push([e.user.email, e.url, getFormatedDate(e.created_at)])
            })
        }


        let csvContent = "data:text/csv;charset=utf-8," + "\ufeff" +
            + exportString.map(e => e.join(",")).join("\n");

        var blob = new Blob(["\uFEFF" + exportString.map(e => e.join(",")).join("\n")], {
            type: 'text/csv; charset=utf-8'
        });


        var encodedUri = encodeURI(csvContent);
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", place.name + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    useEffect(() => {
        if (document.readyState === 'complete') {
            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }

        window.onbeforeunload = function () {

        };

        window.onblur = function () {

        };
    }, []);


    // get userId
    let place = location.state.place;

    return (

        <div>
            <AppBarComponent
                title={APP_NAME}
            />
            <LoadingIndicator
                show={isLoading}
            />
            {/* <AlertMessage
                open={(apiErrorMessage != null && apiErrorMessage != "")}
                handleClose={() => {
                    dispatch(changeApiErrorMessage(""))
                }}
                message={apiErrorMessage}
            /> */}
            <Container sx={{ minWidth: "300px", width: "100vw", margin: "auto", alignContent: "start", justifyContent: "start", justifyItems: "start", alignItems: "start", flexDirection: "row", display: "flex", padding: "0px !important", paddingTop: "0px", margin: "0px !important", maxWidth: "100vw !important" }}>
                <Card variant="outlined" sx={{ minWidth: "300px", width: "300px", margin: "0px", minHeight: "100vh" }}>
                    <SidebarAdmin />
                </Card>

                <Container sx={{ minWidth: "300px", width: "calc(100vw-300px)", margin: "20px", paddingTop: "10px", }}>
                    <div className="MuiContainer-root-clear"></div>
                    <Button variant="contained" color="success" onClick={() => {
                        makeCSV()
                    }}>Download CSV</Button>
                    <br /><br />
                    <Grid container>
                        <Grid item xs={3}>
                            <Container sx={{ textAlign: "center", width: "100%" }} >
                                <img style={{ width: "100px", height: "100px" }} src={IMG_URL + "/" + place.main_image} />
                            </Container>
                            <Container sx={{ textAlign: "center", width: "100%" }} >
                                {place.name}
                            </Container>
                            <Container sx={{ textAlign: "center", width: "100%" }}  >
                                {place.applied_count}/{place.blogger_limit}
                            </Container>
                            <br />

                            <br />
                            <Container style={{ color: "red", textAlign: "center", width: "100%", margin: "0px", padding: "0px", fontSize: 14 }} >
                                신청한 블로거만<br />
                                이미지를 다운받을 수 있습니다<br /><br />


                                신청 후 24시간내에 URL을 올려야합니다.<br />
                                24시간 후에는 URL을 올릴 수 없습니다.


                            </Container>
                            <Typography sx={{ color: "red", textAlign: "center", width: "100%" }} variant="p">

                            </Typography>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={8}>
                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>메인 키워드 : 블로그 내용 본문에 반드시 3번이상 들어가야 합니다.</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                {place.main_keyword} <span style={{ color: "red" }}>(띄어쓰기까지 반드시 일치해야 합니다)</span>
                            </Typography>
                            <br />
                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>서브 키워드 : 블로그 내용 본문에 반드시 1번이상씩 모든 서브키워드가 들어가야합니다.</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                {place.sub_keywords} <span style={{ color: "red" }}>(띄어쓰기까지 반드시 일치해야 합니다)</span>
                            </Typography>
                            <br />

                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>해시태그</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                {place.hashtags.split(",").map((e) => {
                                    return '#' + e.replaceAll(",", " ")
                                }).toString().replaceAll(",", " ")} <span style={{ color: "red" }}>(띄어쓰기까지 반드시 일치해야 합니다)</span>
                            </Typography>
                            <br />
                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>플레이스 이름 및 주소</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                {place.name}<br />
                                <a style={{ color: "blue" }} href={place.place_url} rel="noreferrer" target="_blank">{place.place_url}</a>
                            </Typography>
                            <br />
                            <Typography sx={{ textAlign: "left", width: "100%" }}>
                                <b>제목 및 작성내용 (글자수 및 이미지 갯수)</b>
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                제목 및 작성내용은 자유롭게 해주시면 되고 글자수는 250글자, 이미지는 5개이상이어야합니다.
                            </Typography>
                            <br />
                            <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                                작성 주의사항
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                블로그 제목은 창의적으로 20글자이상 최대한 길게 써주셔야합니다.
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                내용을 다른 블로그 또는 다른 곳에서 복사하거나 비슷하게 사용하면 절대 안됩니다.
                            </Typography>
                            <Typography sx={{ textAlign: "left", color: "red" }}>
                                (블로그 포스팅이 누락되거나 저품질이 되면 URL이 승인되더라도 대금은 지급되지 않을 수 있습니다)
                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                누락여부는 아래 사이트에서 확인하실 수 있습니다

                            </Typography>
                            <Typography sx={{ textAlign: "left" }}>
                                <a style={{ color: "blue" }} href="https://whereispost.com/" rel="noreferrer" target="_blank">https://whereispost.com/</a>
                            </Typography>
                            <br />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6">Bloggers</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell align="center">Applied At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {place.bloggers && place.bloggers.length > 0 ? (
                                            place.bloggers.map((e, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{e.user.email}</TableCell>
                                                    <TableCell align="center">{getFormatedDate(e.created_at)}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={2} align="center">
                                                    No Bloggers Available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={7.5}>
                            <Typography variant="h6">Verified URL</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Email</TableCell>
                                            <TableCell align="center">URL</TableCell>
                                            <TableCell align="center">Verify At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {place.verified_submissions && place.verified_submissions.length > 0 ? (
                                            place.verified_submissions.map((e, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{e.user.email}</TableCell>
                                                    <TableCell align="center">{e.url}</TableCell>
                                                    <TableCell align="center">{getFormatedDate(e.created_at)}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">
                                                    No Verified Submissions Available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        
                        <Grid item xs={12}>
                        <br/><br/>
                            <Typography variant="h6">Image Downloaded</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Downloaded By</TableCell>
                                            <TableCell align="center">Downloaded At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {place.images && place.images.length > 0 ? (
                                            place.images.filter((e)=>e.downloaded_by!=null).map((e, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{e.user.email}</TableCell>
                                                    <TableCell align="center">{e.downloaded_at}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">
                                                    No Downloads
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
            </Container>

        </div >

    );
}


function getFormatedDate(dateNow) {
    const date = new Date(dateNow);

    // Extract the individual components of the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the components into the desired format
    const formattedDate = `${year}.${month}.${day} ${hours}.${minutes}.${seconds}`;


    return formattedDate;
}


export default PlaceDetailAdminView;