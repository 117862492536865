import { Button, Card, Container } from "@mui/material";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import SidebarAdmin from "../../common/views/components/SideBarAdmin";
import { useDispatch, useSelector } from "react-redux";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { DataGrid, GridColDef, GridValueGetterParams, koKR } from '@mui/x-data-grid';
import { changeFaqModalOpen, deleteFaq, getfaq, getusers, setFormErrors, setLoadingHidden, setLoadingScreen, setUpdateFaq } from "../state/admin_home_state";
import { useEffect } from "react";
import { FaqModal } from "./FaqModal";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import { Add, Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";


function FAQViewAdmin() {

    const faqs = useSelector((state) => state.adminhome.faqList)
    const faqModalOpen = useSelector((state) => state.adminhome.faqModalOpen)
    const isLoading = useSelector((state) => state.adminhome.formProcessing)

    var dispatch = useDispatch()

    function handleDelete(id) {

        dispatch(setLoadingScreen())
        dispatch(deleteFaq({ id: id })).unwrap().then(() => {
            dispatch(setLoadingHidden())
            dispatch(setFormErrors(""))
            toast.success("Deleted FAQ Successfully")
            dispatch(getfaq({}))

        })
    }

    function initState() {

        dispatch(getfaq({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {

            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);


    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { 
            field: 'title',
            headerName: 'Title',
            flex: 1.5,
            renderCell: (params) => {
                return <div className="post__content" dangerouslySetInnerHTML={{ __html: params.row.faq.title }}></div>
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            flex: 0.6,
            renderCell: (params) => {
                return <div>
                    <Button variant="contained" onClick={() => {

                        dispatch(setUpdateFaq(params.row.faq))
                    }} color="warning"><Edit /> Edit</Button> &nbsp;
                    <Button variant="contained" color="error" onClick={() => {

                        handleDelete(params.row.faq.id)
                    }} ><Delete /> Delete</Button>
                </div>
            }
        },
    ];

    var rows = [

    ];

    if (faqs != null) {
        rows = (faqs).map((faq, index) => {
            return { id: index + 1, title: faq.title, faq: faq }
        })
    }

    return (

        <div>
            <LoadingIndicator
                show={isLoading}
            />
            <AppBarComponent
                title={APP_NAME}
                isAdmin={true}
            />

            <Container sx={{ minWidth: "300px", width: "100vw", margin: "auto", alignContent: "start", justifyContent: "start", justifyItems: "start", alignItems: "start", flexDirection: "row", display: "flex", padding: "0px !important", paddingTop: "0px", margin: "0px !important", maxWidth: "100vw !important" }}>
                <Card variant="outlined" sx={{ minWidth: "200px", width: "300px", margin: "0px", minHeight: "100vh" }}>
                    <SidebarAdmin />
                </Card>
                <Container sx={{ minWidth: "300px", width: "calc(100vw-300px)", margin: "0px", paddingTop: "10px",maxWidth:'unset !important'  }}>

                    <Container sx={{ margin: 0, textAlign: "left" }}>
                        <Button type="button" onClick={() => {
                            dispatch(changeFaqModalOpen())
                        }} variant="contained" color="success"><Add /> Add New</Button>
                    </Container>
                    <br />
                    <div style={{ height: '70vh', width: '100%',maxWidth:'unset !important'  }}>
                        <DataGrid
                            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            rowSelection={false}

                        />
                    </div>
                </Container>
            </Container>
            <FaqModal
                open={faqModalOpen}
                handleClose={() => {
                    dispatch(changeFaqModalOpen())
                }}
            />
        </div>

    );
}

export default FAQViewAdmin;