import { AccountCircle, Lock, Message, Phone, } from "@mui/icons-material";
import { Avatar, Button, Card, CardContent, Container, Divider, FormControl, InputAdornment, TextField, Typography } from "@mui/material"
import AppBarComponent from "../../common/views/components/AppBarComponent";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { useDispatch, useSelector } from "react-redux";
import { changeFormNo, changeUserBlogId, changeUserConfirmPassword, changeUserEmail, changeUserPassword, changeUserPhone, registerUser, setFormSingleErrors, setLoadingHidden, setLoadingScreen } from "../state/register_state";
import AlertDialog from "../../common/views/components/AlertBox";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import ErrorIndicator from "../../common/views/components/ErrorIndicator";
import { Link } from "react-router-dom";
import IMask from 'imask';
import { lazy, useEffect } from "react";

function RegisterView() {

    const user = useSelector((state) => state.register.user)
    const singleErrorMessage = useSelector((state) => state.register.singleErrorMessage)
    const errorMessage = useSelector((state) => state.register.errorMessage)
    const isLoading = useSelector((state) => state.register.isLoading)

    var dispatch = useDispatch()

    function handleRegister() {
        dispatch(setLoadingScreen())
        dispatch(registerUser({ email: user.email, password: user.password, confirm_password: user.confirm_password, phone: user.phone, blog_id: user.blog_id })).unwrap().then(() => {
            dispatch(setLoadingHidden())
        })
    }

    function checkPhone(phone) {

        if (phone == null || !phone.replaceAll("-", "").match(/^[010]{3}[0-9]{8}$/)) {

            dispatch(setFormSingleErrors("핸드폰번호가 잘못되었습니다. 올바른 핸드폰번호로 다시 입력해주세요"))
            return false
        }
    }


    useEffect(() => {
        if (document.readyState === 'complete') {

            var element = document.getElementById('blog_id');
            var maskOptions = {
                mask: /^[a-zA-Z0-9_.-]*$/,
                // mask: '{https://blog.n\\aver.com/}[0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a]',
                lazy: false
            };
            var mask = IMask(element, maskOptions);
            // console.log(mask)
        } else {
            window.onload = function () {
                var element = document.getElementById('blog_id');
                var maskOptions = {
                    mask: /^[a-zA-Z0-9_.-]*$/,

                    // mask: '{https://blog.n\\aver.com/}[0][_][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a][0][a]',
                    lazy: false
                };
                var mask = IMask(element, maskOptions);
                // console.log(mask)
            }
        }
    }, []);
    return (

        <div>
            <LoadingIndicator
                show={isLoading}
            />
            <AppBarComponent
                title={APP_NAME}
                drawerWidth={0}
            />
            <AlertDialog
                show={singleErrorMessage != null && singleErrorMessage != ""}
                message={singleErrorMessage}
            />
            <br /><br />
            <Card sx={{ minWidth: "300px", width: { xs: 'auto', sm: 'auto', md: '40vw' }, marginTop: 10,  marginLeft: { xs: 2, sm: 2, md: 'auto' }, marginRight: { xs: 2, sm: 2, md: 'auto' } }}>
                <CardContent>
                    <FormControl sx={{ textAlign: "left", width: '100%', marginBottom: 2, }}>
                        <ErrorIndicator
                            message={errorMessage}
                        />
                    </FormControl>
                    <FormControl sx={{ textAlign: "left", width: '100%', marginBottom: 3, }}>
                        <Typography variant="h6">
                            가입하기
                        </Typography>
                    </FormControl>
                    <FormControl sx={{ width: '100%', marginBottom: 2, }}>

                        <TextField
                            required
                            id="email"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            type="email"
                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                            label="Email"
                            defaultValue=""
                            value={user.email ?? ''}
                            onChange={(e) => {
                                dispatch(changeUserEmail(e.target.value))
                            }}
                            placeholder="Email을 입력해주세요."
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%', marginBottom: 2, }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            id="password"
                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                            label="Password"
                            type="password"
                            defaultValue=""
                            value={user.password ?? ''}
                            onChange={(e) => {
                                dispatch(changeUserPassword(e.target.value))
                            }}
                            placeholder="비밀번호를 입력해주세요."

                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%', marginBottom: 2, }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            id="confirm_password"
                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                            label="Confirm Password"
                            type="password"
                            defaultValue=""
                            value={user.confirm_password ?? ''}
                            onChange={(e) => {
                                dispatch(changeUserConfirmPassword(e.target.value))
                            }}
                            placeholder="비밀번호를 다시한번 입력해주세요."

                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%', marginBottom: 2, }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Phone />
                                    </InputAdornment>
                                ),
                            }}
                            id="phone"
                            type="text"
                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                            label="핸드폰번호"
                            defaultValue=""
                            value={user.phone ?? ''}
                            onChange={(e) => {
                                const re = /^[0-9\b]+$/

                                if (e.target.value.match(re) || e.target.value == '') {
                                    dispatch(changeUserPhone(e.target.value))
                                }
                            }}
                            onBlur={(e) => {
                                checkPhone(e.target.value)
                            }}
                            placeholder="핸드폰번호"

                        />
                    </FormControl>

                    <FormControl sx={{ width: '100%', marginBottom: 2, }}>
                        <TextField
                            width="100%"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {"https://blog.naver.com/"}
                                    </InputAdornment>
                                ),
                            }}
                            id="blog_id"
                            sx={{ '.MuiInputBase-input': { fontSize: '14px' }, }}
                            label="Blog 주소"
                            defaultValue=""
                            value={(user.blog_id ?? '').replaceAll("https://blog.naver.com/","")}
                            onChange={(e) => {
                                // const re = /^https:\/\/blog.naver.com\/([a-zA-Z0-9\b_])*$/

                                // if (e.target.value.match(re) || e.target.value == '') {
                                dispatch(changeUserBlogId("https://blog.naver.com/"+e.target.value))
                                // }
                            }}
                            placeholder="Blog 주소"

                        />

                        <Typography sx={{ textAlign: "left" }}>
                            <p>아래의 Blog 주소 형식에 맞게 입력해주세요</p>
                        </Typography>
                        <Typography sx={{ textAlign: "left" }}>
                            <span>https://blog.naver.com/blogid</span>
                        </Typography>
                    </FormControl>

                    <FormControl sx={{ width: '100%', marginBottom: 2, justifyContent: 'center', flexDirection: "row" }}>

                        <Typography >
                            <b></b>
                        </Typography>

                        <Button variant="contained" sx={{ width: '100px' }} onClick={() => {
                            handleRegister()
                        }} disableElevation>
                            가입하기
                        </Button>
                    </FormControl>
                    <FormControl sx={{ width: '100%', marginBottom: 2, textAlign: "center" }}>
                        <center>
                            <Divider />
                        </center>
                    </FormControl>
                    <br />
                    <FormControl sx={{ width: '100%', marginBottom: 2, flexDirection: "row", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                        <Link to="/">

                            <Typography>
                                <b>Login</b>
                            </Typography>
                        </Link>
                    </FormControl>

                </CardContent>
            </Card>
        </div>

    );
}

export default RegisterView;