import { Alert } from "@mui/material";

function ErrorIndicator({ message }) {

  return (
    message = null || message == "" ? (<div></div>) : (
      <Alert severity="error">{message.toString().split("\n").map((widget) => {
        return (<div>
          {(widget.replaceAll("Validation Error","유효성 검사 오류").replaceAll("The url field is required.", "URL 필드는 필수입니다.").replaceAll("The email has already been taken.", "이미 등록된 Email입니다. 다른 Email을 입력해주세요.").replaceAll("The blogid has already been taken.", "이미 등록된 Blog ID입니다. 다른 Blog ID를 입력해주세요.").replaceAll("Blog id already exists!","이미 등록된 Blog ID입니다. 다른 Blog ID를 입력해주세요.").replaceAll("Email already exists!","이미 등록된 Email입니다. 다른 Email을 입력해주세요").replaceAll("Account blocked","정지된 계정입니다. 관리자에게 문의하세요."))}
          <br />
        </div>)
      })}</Alert>
    )
  );
}

function translateError(widget) {
  var msg = widget

  msg.replaceAll("The blogid has already been taken.", "이미 등록된 Blog ID입니다. 다른 Blog ID를 입력해주세요.")
  msg.replaceAll("The email has already been taken.", "이미 등록된 Email입니다. 다른 Email을 입력해주세요.")
  msg.replaceAll("The url field is required.", "URL 필드는 필수입니다.")

  return msg
}

export default ErrorIndicator;
