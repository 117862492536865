import { Button, Card, Container } from "@mui/material";
import AppBarComponent from "../../common/views/components/AppBarComponent";
import SidebarAdmin from "../../common/views/components/SideBarAdmin";
import { useDispatch, useSelector } from "react-redux";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { DataGrid, GridColDef, GridValueGetterParams, koKR } from '@mui/x-data-grid';
import { bankApprove, changeFaqModalOpen, deleteFaq, deleteRequests, getbankapprove, getblogs, getfaq, getrequests, getusers, setFormErrors, setLoadingHidden, setLoadingScreen, setUpdateFaq, setUpdateRequest } from "../state/admin_home_state";
import { useEffect } from "react";
import { FaqModal } from "./FaqModal";
import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import { Add, Approval, Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { RequestModal } from "./RequestsModal";


function BankRequestsView() {

    const requests = useSelector((state) => state.adminhome.bankApprove)
    const faqModalOpen = useSelector((state) => state.adminhome.faqModalOpen)
    const isLoading = useSelector((state) => state.adminhome.formProcessing)

    var dispatch = useDispatch()

    function approveBank(id) {

        dispatch(setLoadingScreen())
        dispatch(bankApprove({ id: id })).unwrap().then(() => {
            dispatch(setLoadingHidden())
            dispatch(setFormErrors(""))
            toast.success("Approved Successfully")
            dispatch(getbankapprove({}))

        })
    }

    function initState() {

        dispatch(getbankapprove({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {

            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);


    const columns = [
        { field: 'id', headerName: 'ID', width: 100, },
        { field: 'email', headerName: 'Email', width: 200, },
        { field: 'bank_name', headerName: 'Bank Name', width: 100, },
        { field: 'account_number', headerName: 'Account Number', width: 100, },
        { field: 'account_name', headerName: 'Account Name', width: 100, },
        { field: 'identification', headerName: 'Identification', width: 100, },
        { field: 'time', headerName: 'Apply Time', width: 150, },

        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 300,
            renderCell: (params) => {
                return <div>
                    <Button variant="contained" onClick={() => {
                        approveBank(params.row.request.id)
                    }} color="warning"><Approval /> Approve</Button>
                </div>
            }
        },
    ];

    var rows = [

    ];

    if (requests != null) {
        rows = (requests).map((request, index) => {
            return {
                id: index + 1,
                email: request.user.email,
                bank_name: request.bank_name,
                account_number: request.account_number,
                account_name: request.account_name,
                time: getFormatedDate(request.created_at),
                identification: request.identification_number,
                request: request
            }
        })
    }

    return (

        <div>
            <LoadingIndicator
                show={isLoading}
            />
            <AppBarComponent
                title={APP_NAME}
                isAdmin={true}
            />

            <Container sx={{ minWidth: "300px", width: "100vw", margin: "auto", alignContent: "start", justifyContent: "start", justifyItems: "start", alignItems: "start", flexDirection: "row", display: "flex", padding: "0px !important", paddingTop: "0px", margin: "0px !important", maxWidth: "100vw !important" }}>
                <Card variant="outlined" sx={{ minWidth: "200px", width: "300px", margin: "0px", minHeight: "100vh" }}>
                    <SidebarAdmin />
                </Card>
                <Container sx={{ minWidth: "300px", width: "calc(100vw-300px)", margin: "0px", paddingTop: "10px",maxWidth:'unset !important'  }}>

                    <div style={{ height: '70vh', width: '100%',maxWidth:'unset !important'  }}>
                        <DataGrid
                            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}

                            sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    textAlign: "center"
                                },
                                "& .MuiDataGrid-cellContent": {
                                    whiteSpace: "normal",
                                    lineHeight: "normal",
                                    textAlign: "left"
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    // Forced to use important since overriding inline styles
                                    height: "unset !important"
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    // Forced to use important since overriding inline styles
                                    maxHeight: "168px !important"
                                }
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={5}

                            rowsPerPageOptions={[5]}
                            rowSelection={false}

                        />
                    </div>
                </Container>
            </Container>

        </div>

    );
}

function getFormatedDate(dateNow) {
    const date = new Date(dateNow);

    // Extract the individual components of the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the components into the desired format
    const formattedDate = `${year}.${month}.${day} ${hours}.${minutes}.${seconds}`;


    return formattedDate;
}


export default BankRequestsView;