import AppliedView from "./AppliedView";

function Applied() {

    return (
        <div className="App">
            <AppliedView />
        </div>
    );
}

export default Applied;