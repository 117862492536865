import { Box, Button, Modal, Typography } from "@mui/material";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
   

    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '20px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}


export function AlertMessage({ open, handleClose, message }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
                    {message}
                    <br /> <br />
                    <Button variant="contained" sx={{ width: '100px', }} onClick={() => {
                        handleClose()
                    }} disableElevation>
                        확인
                    </Button>
                </Typography>
            </Box>
        </Modal>
    )
}