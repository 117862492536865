import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { faqListGet, setLoadingScreen } from "../state/faq_state";
import { ExpandMore, } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Card, Box, Toolbar, CardContent, Typography } from "@mui/material"
import { APP_NAME } from "../../common/constants/ConfigApp";

import AppBarComponent from "../../common/views/components/AppBarComponent";
import Sidebar from "../../common/views/components/SideBar";
import CssBaseline from '@mui/material/CssBaseline';
import LoadingIndicator from "../../common/views/components/LoadingIndicator";

function FaqView() {

    const faqList = useSelector((state) => state.faq.faqList)
    const isLoading = useSelector((state) => state.home.isLoading)

    const dispatch = useDispatch()

    const [mobileOpen, setMobileOpen] = useState(false);

    function initState() {
        dispatch(setLoadingScreen({}))
        dispatch(faqListGet({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {
            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerWidth = 300;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBarComponent
                title={APP_NAME}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Sidebar
                drawerOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, maxWidth: '100%', width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                
                <LoadingIndicator
                    show={isLoading}
                />

                <Box sx={{ padding: 0, }}>
                    <Card sx={{ margin: "auto" }}>
                        <CardContent>
                            <Typography variant="h6">
                                FAQ
                            </Typography>

                            <br /><br />

                            {faqList != null ? faqList.map((faq) => {

                                return <Accordion sx={{width: '100%'}}>
                                    <AccordionSummary
                                        sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <div className="post__content" style={{ fontSize: '14px', textAlign: 'left', }} dangerouslySetInnerHTML={{ __html: faq.title }}></div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className="post__content" style={{ fontSize: '14px', }} dangerouslySetInnerHTML={{ __html: faq.content }}></div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            }) : <div></div>}
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
}

export default FaqView;