import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { IconButton, Box, ImageListItemBar, Toolbar, Typography } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ImageListItem, { imageListItemClasses } from "@mui/material/ImageListItem";
import { getprofile, loadallplaces, loadappliedplaces, loadcompletedplaces, setAddFormErrors, setLoadingScreen } from "../state/home_state";
import { APP_NAME } from "../../common/constants/ConfigApp";
import { IMG_URL } from "../../common/repository/api_routes";

import LoadingIndicator from "../../common/views/components/LoadingIndicator";
import CssBaseline from '@mui/material/CssBaseline';
import AppBarComponent from "../../common/views/components/AppBarComponent";
import Sidebar from "../../common/views/components/SideBar";

function HomeView() {

    const allplaces = useSelector((state) => state.home.allplaces)
    const isLoading = useSelector((state) => state.home.isLoading)

    const [mobileOpen, setMobileOpen] = useState(false);

    const navigator = useNavigate();
    const dispatch = useDispatch()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function initState() {
        dispatch(setAddFormErrors(""))
        dispatch(setLoadingScreen({}))
        dispatch(getprofile({}))
        dispatch(loadallplaces({}))
        dispatch(loadappliedplaces({}))
        dispatch(loadcompletedplaces({}))
    }

    useEffect(() => {
        if (document.readyState === 'complete') {
            initState();
        } else {
            window.onload = function () {
                initState();
            }
        }
    }, []);

    var usernew = JSON.parse(localStorage.getItem("user"))
    var allplacesnotverified;
    var allplacesverified;

    if (allplaces != null) {
        var date = new Date();

        const options = { timeZone: 'Asia/Seoul', year: "numeric",
        month: "2-digit",
        day: "2-digit", };
        const koreanDate = date.toLocaleString('en-US', options);

        var today = koreanDate.split('/')[2] + '-' + koreanDate.split('/')[0] + '-' + koreanDate.split('/')[1];

        allplacesnotverified = allplaces.filter((e) => e.verified_submissions.filter((el) => {
            var cdate = new Date(el.created_at);

            const options = { timeZone: 'Asia/Seoul', year: "numeric",
            month: "2-digit",
            day: "2-digit", };
            const koreanCDate = cdate.toLocaleString('en-US', options);

            var createdDate = koreanCDate.split('/')[2] + '-' + koreanCDate.split('/')[0] + '-' + koreanCDate.split('/')[1];

            return (el.user_id == usernew[0]['id'] && createdDate == today);
        }).length <= 0);

        allplacesverified = allplaces.filter((e) => e.verified_submissions.filter((el) => {
            var cdate = new Date(el.created_at);

            const options = { timeZone: 'Asia/Seoul', year: "numeric",
            month: "2-digit",
            day: "2-digit", };
            const koreanCDate = cdate.toLocaleString('en-US', options);

            var createdDate = koreanCDate.split('/')[2] + '-' + koreanCDate.split('/')[0] + '-' + koreanCDate.split('/')[1];

            return (el.user_id == usernew[0]['id'] && createdDate == today);
        }).length > 0)
    }

    const drawerWidth = 300;

    const theme = createTheme({
        breakpoints: {
          values: {
            mobile: 0,
            bigMobile: 350,
            tablet: 650,
            desktop: 1000
          }
        }
    });

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBarComponent
                title={APP_NAME}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Sidebar
                drawerOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
            />

            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                
                <LoadingIndicator
                    show={isLoading}
                />
                
                <Box sx={{ padding: 0, }}>
                    <ThemeProvider theme={theme}>
                        <Box
                            sx={{
                                display: "grid",
                                gap: 2,
                                gridTemplateColumns: {
                                    mobile: "repeat(2, minmax(0, 1fr))",
                                    bigMobile: "repeat(2, minmax(0, 1fr))",
                                    tablet: "repeat(2, minmax(0, 1fr))",
                                    desktop: "repeat(4, minmax(0, 1fr))",
                                },
                                [`& .${imageListItemClasses.root}`]: {
                                    display: "flex",
                                    flexDirection: "column"
                                }
                            }}
                        >
                        {allplacesnotverified != null ? allplacesnotverified.map((item) => {
                                return <div onClick={() => {
                                    if (item.searched_url != null && item.searched_url != "") {
                                        window.open(item.searched_url, "_blank", 'noreferer,width=600,height=600')
                                    }

                                    navigator("/details", {
                                        state: {
                                            place: item,
                                        }
                                    })
                                }}>
                                    <ImageListItem key={item.img} sx={{ padding: 0, '.MuiImageListItem': {height: '100%'} }} >
                                        <Box
                                            component="img"
                                            src={`${IMG_URL + "/" + item.main_image}`}
                                            alt={item.name}
                                            sx={{ height: { mobile: '120px', bigMobile: '120px', tablet: "250px", desktop: "250px" }, objectFit: 'cover', }}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            sx={{ padding: 0, '.MuiImageListItemBar-titleWrap': { padding: '8px' } }}
                                            title={
                                                <Box>
                                                    <Typography sx={{ fontSize: 15, margin: 0, padding: 0 }}>{item.name}</Typography>

                                                    <IconButton
                                                        sx={{ color: 'rgba(255, 255, 255, 0.54)', padding: 0, }}
                                                        aria-label={`info about ${item.title}`}
                                                    >
                                                        <Typography style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>
                                                            {item.applied_count}/{item.blogger_limit}
                                                        </Typography>
                                                    </IconButton>
                                                </Box>
                                            }
                                        />
                                    </ImageListItem>
                                </div>
                            }) : <div></div>}

                            {allplacesverified != null ? allplacesverified.map((item) => {
                                return <div onClick={() => {}}>
                                    <ImageListItem key={item.img} sx={{ padding: 0, '.MuiImageListItem': {height: '100%'} }}>
                                        <div style={{ backgroundColor: "rgba(255,255,255,0.67)", width: "100%", height: "100%", zIndex: "99999", position: "absolute", textAlign: "center" }}>
                                            <div style={{ height: "100%", alignContent: "center", justifyContent: "center", alignItems: "center", display: "flex" }}><Typography sx={{ fontSize: 28, fontWeight: "bold", textAlign: "center" }}>완료</Typography></div>
                                        </div>
                                        <Box
                                            component="img"
                                            src={`${IMG_URL + "/" + item.main_image}`}
                                            alt={item.name}
                                            sx={{ height: { mobile: '120px', bigMobile: '120px', tablet: "250px", desktop: "250px" }, objectFit: 'cover', }}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            sx={{ padding: 0, '.MuiImageListItemBar-titleWrap': { padding: '8px' }, textAlign: 'left' }}
                                            title={
                                                <Box>
                                                    <Typography sx={{ fontSize: 15, margin: 0, padding: 0 }}>{item.name}</Typography>

                                                    <IconButton
                                                        sx={{ color: 'rgba(255, 255, 255, 0.54)', padding: 0 }}
                                                        aria-label={`info about ${item.title}`}
                                                    >
                                                        <Typography style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>
                                                            {item.applied_count}/{item.blogger_limit}
                                                        </Typography>
                                                    </IconButton>
                                                </Box>
                                            }
                                        />
                                    </ImageListItem>
                                </div>
                            }) : <div></div>}
                        </Box>
                    </ThemeProvider>
                </Box>
            </Box>
        </Box>
    );
}

export default HomeView;