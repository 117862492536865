import logo from './logo.svg';
import './App.css';
import AppBarComponent from './modules/common/views/components/AppBarComponent';
import LoginView from './modules/login/views/LoginView';

function App() {
  return (
    <div className="App">

      <LoginView />

    </div>
  );
}

export default App;
